<template>
  <div class="with-us">
    <ul class="with-us__list">
      <li class="with-us__item" v-for="(item, index) in items" :key="index">
        <p class="with-us__number" :style="`color: ${item.color[page]}`">
          {{ item.amount
          }}<span class="with-us__units" v-if="item.units">
            {{ item.units }}</span
          >
        </p>
        <p class="with-us__text">{{ item.text }}</p>
      </li>
      <li class="with-us__mobile">... и это только начало</li>
    </ul>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component()
export default class WithUs extends Vue {
  @Prop({ type: String, default: "" }) title;
  @Prop({ type: String, default: "index" }) page;

  items = [
    {
      amount: "2 643",
      color: {
        index: "#A562FF",
        about: "#A562FF",
      },
      text: "работающих таксопарков",
    },
    {
      amount: "388 662",
      color: {
        index: "#38CE97",
        about: "#38CE97",
      },
      text: "активных водителей",
    },
    {
      amount: "18,1",
      color: {
        index: "#005051",
        about: "#A562FF",
      },
      text: "успешных транзакций",
      units: "млн.",
    },
  ];
}
</script>

<style lang="scss" scoped>
.with-us {
  &__list {
    margin: 0 auto;
    margin-top: 65px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  &__mobile {
    display: none;
  }

  &__item {
    margin-left: auto;

    &:not(:last-child) {
      margin-right: 20px;
    }

    &:first-child {
      max-width: 217px;
      padding-left: 0;
      margin-left: 0;
      margin-left: 87px;
    }

    &:nth-child(3) {
      .with-us__text {
        line-height: 29px;
      }
    }
  }

  &__number {
    font-family: "GTAmericaBold", "Roboto Medium";
    font-style: normal;
    font-weight: 700;
    font-size: 112px;
    line-height: 104px;
    /* letter-spacing: -4px; */

    text-transform: uppercase;
    text-align: left;
  }

  &__units {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0;
  }

  &__text {
    margin-top: 17px;
    max-width: 95%;
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 30px;
    /* letter-spacing: 3px; */
    text-align: left;

    color: #000000;
  }

  @media (max-width: 600px) {
    .with-us {
      &__list {
        padding: 0;
        justify-content: space-around;
        margin-top: 33px;
        row-gap: 34px;
        padding-left: 6vw;
        padding-right: 6vw;
        max-width: 400px;
        box-sizing: border-box;
      }

      &__units {
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
      }

      &__mobile {
        font-family: "Roboto";
        display: block;
        max-width: 141px;

        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
        color: #000000;

        text-align: left;
        /* letter-spacing: 2px; */
      }

      &__item {
        margin-right: 0;
        margin-left: 0;

        &:nth-child(odd) {
          max-width: 111px;
          padding-left: 0;
          margin-left: 0;
        }

        &:nth-child(3) {
          .with-us__text {
            line-height: 15px;
          }
        }
      }

      &__number {
        font-weight: 700;
        font-size: 46px;
        line-height: 100%;
      }

      &__text {
        max-width: 100%;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        margin-top: 5px;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    &__item {
      &:first-child {
        max-width: 302px;
        margin-left: 101px;
      }

      &:not(:last-child) {
        margin-right: 0px;
      }

      &:nth-child(3) {
        .with-us__number {
          padding-left: 1.2vw;
        }

        .with-us__text {
          position: relative;
          max-width: 95%;
          left: 1.3vw;
          line-height: 2.5vw;
        }
      }
    }
    .with-us {
      &__list {
        margin-top: 5vw;
      }

      &__number {
        font-size: 6.5vw;
        line-height: 6vw;
      }

      &__text {
        max-width: 100%;
        font-size: 1.88vw;
        line-height: 2.5vw;
        margin-top: 1.4vw;
      }

      &__item {
        &:first-child {
          max-width: 24vw;
          margin-left: 8vw;
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    &__item {
      &:first-child {
        max-width: 302px;
        margin-left: 101px;
      }

      &:not(:last-child) {
        margin-right: 0px;
      }

      &:nth-child(3) {
        .with-us__number {
          padding-left: 15px;
        }

        .with-us__text {
          position: relative;
          max-width: 95%;
          left: 12px;
        }
      }
    }
    .with-us {
      &__number {
        font-size: 84px;
        line-height: 78px;
      }

      &__text {
        max-width: 100%;
        font-size: 24px;
        line-height: 29px;
      }

      &__item {
        &:first-child {
          max-width: 302px;
          margin-left: 101px;
        }
      }
    }
  }
}
</style>
