<template>
  <div :class="`banner banner-${page}`">
    <div class="banner__wrapper">
      <h1 class="banner__title" v-html="banners[page].title"></h1>
      <p
        :class="`banner__text ${pota ? `` : `banner__text-${page}`}`"
        v-if="page !== 'about' && page !== 'driver-new'"
      >
        <!-- {{ pota ? "Официальный партнер QIWI" : "Генеральный партнер QIWI" }} -->
      </p>
      <p
        class="banner__text"
        style="text-align: left; width: fit-content"
        v-else
      >
        Станьте водителем или курьером <br />
        и получайте деньги каждый день.<br />Без задержек.
      </p>
      <ul class="banner__list">
        <li
          class="banner__item"
          v-for="btn in banners[page].btns"
          :key="btn.id"
        >
          <router-link
            v-if="btn.router && !pota"
            class="banner__router"
            :class="`banner__router-${btn.name}`"
            :to="btn.href"
            >{{ btn.title }}</router-link
          >
          <a
            v-else-if="pota"
            class="banner__router"
            :class="`banner__router-${btn.name} banner__router-${btn.name}--${
              pota ? `pota` : ``
            }`"
            href="#pota"
            >{{ pota ? "Описание" : btn.title }}</a
          >
          <a
            v-else-if="page === 'driver' || page === 'driver-new'"
            class="banner__router"
            :class="`banner__router-${btn.name}`"
            :href="btn.href + params"
            target="_blank"
            >{{ btn.title }}</a
          >
          <button
            :class="`banner__btn banner__btn-${btn.name}`"
            v-else
            @click="btn.action"
          >
            <i v-if="btn.icon" :class="`icon-${btn.icon}`"></i>
            {{ btn.title }}
          </button>
        </li>
      </ul>
      <p
        v-if="page !== 'driver' && page !== 'driver-new'"
        class="banner__description"
        :style="pota ? 'visibility: hidden' : ''"
      >
        Доступно на iOS, Android, Browser
      </p>
      <p
        v-else
        class="banner__description"
        :style="page === 'driver-new' ? 'visibility: hidden;' : ''"
      >
        Доступно на iOS, Android<span v-if="page === 'driver-new'"
          >, Huawei</span
        >
      </p>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component()
export default class MainPage extends Vue {
  @Prop({ type: String, default: "index" }) page;
  @Prop({ type: Boolean, default: false }) pota;
  @Prop({ type: String, default: "" }) params;
  banners = {
    index: {
      title: "Система управления таксопарком",
      btns: [
        {
          id: 1,
          name: "driver",
          title: "Для водителей",
          href: "/driver",
          router: true,
        },
        {
          id: 2,
          name: "park",
          title: "Для таксопарков",
          href: "/park",
          router: true,
        },
      ],
    },
    driver: {
      title: `Деньги <br> в ваших руках`,
      btns: [
        {
          id: 1,
          title: "Стать водителем",
          name: "bedriver",
          href: "/driver-new",
          router: true,
        },
        {
          id: 2,
          title: "Скачать",
          name: "install",
          href: "https://fintaxidriver.page.link/driver",
          router: false,
        },
      ],
    },
    park: {
      title: `Виртуальное решение реальных задач`,
      btns: [
        {
          id: 1,
          name: "demonstration",
          title: "Демонстрация",
          icon: "youtube",
          action: this.onClickDemonstartion,
        },
        {
          id: 2,
          name: "plug",
          title: "Подключить",
          action: this.onClickInstall,
        },
      ],
    },
    about: {
      title: `Создаем удобные и эффективные IT-решения&nbsp; <br> для сферы такси`,
      btns: [
        {
          id: 1,
          name: "driver",
          title: "Для водителей",
          href: "/driver",
          router: true,
        },
        {
          id: 2,
          name: "park",
          title: "Для таксопарков",
          href: "/park",
          router: true,
        },
      ],
    },
    "driver-new": {
      title: `Получите работу <br> за 1 час`,
      btns: [
        {
          id: 1,
          name: "install",
          title: "Получить работу",
          href: `https://forms.taxiaggregator.ru/market`,
        },
      ],
    },
  };

  btns = [
    {
      id: 1,
      name: "driver",
      title: "Для водителей",
      href: "/driver",
      router: true,
    },
    {
      id: 2,
      name: "park",
      title: "Для таксопарков",
      href: "/park",
      router: true,
    },
  ];

  onClickInstall() {
    this.$router.push("/connect");
  }

  onClickDemonstartion() {
    console.log("Демонстрация");
  }

  /* get downloadLink() {
    if (/iPhone|iPad/i.test(navigator.userAgent)) {
      return "https://apps.apple.com/ru/app/таксиагрегатор/id1477165599";
    } else if (/android/i.test(navigator.userAgent)) {
      return "https://play.google.com/store/apps/details?id=agregator.taxi.fintaxidriver";
    } else if (/huawei/i.test(navigator.userAgent)) {
      return "https://appgallery.huawei.com/#/app/C102733117";
    } else {
       return "/";
    }
  } */
}
</script>

<style lang="scss" scoped>
.banner {
  height: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 186px 87px 33px 0;
  background: #a562ff;
  border-radius: 28px;
  background-image: url("@/assets/img/index-banner.png");
  background-repeat: no-repeat;
  background-position: bottom left 5px;
  background-size: 106%;

  &-driver,
  &-driver-new {
    padding-top: 238px;
    padding-bottom: 31px;
    background: #38ce97;
    background-image: url("@/assets/img/driver-banner.png");
    background-repeat: no-repeat;

    .banner {
      &__list {
        margin-top: 131px;
        justify-content: flex-end;
      }

      &__item {
        max-width: 212px !important;
        margin-right: 0 !important;
      }

      &__btn {
        background: #005051;
      }

      &__description {
        color: #fff;
      }

      &__text {
        margin-top: 49px;
      }
    }
  }

  &-driver {
    .banner {
      &__list {
        justify-content: space-between;
        max-width: 440px;
      }
    }
  }

  &-driver-new {
    background-size: 107%;
    .banner__item {
      max-width: 239px !important;
    }
  }

  &-about {
    padding-top: 170px;
    background: #38ce97;
    background-image: url("@/assets/img/about-banner.png");
    background-repeat: no-repeat;

    .banner {
      &__list {
        margin-top: 65px;
      }

      &__description {
        color: rgba(255, 255, 255, 0.5);
      }

      &__router {
        &-driver {
          background: #005051;
        }

        &-park {
          background: #40256b;
        }
      }
    }
  }

  &-park {
    padding-top: 97px;
    background: #40256b;
    background-image: url("@/assets/img/park-banner.png");
    background-repeat: no-repeat;

    .banner {
      &__title {
        max-width: 635px;
      }

      &__text {
        margin-top: 49px;
      }

      &__list {
        margin-top: 62px;
        max-width: 469px;
      }

      &__item {
        max-width: 227px !important;
        margin-right: 0 !important;
      }

      &__btn {
        &-demonstration {
          visibility: hidden;
          position: relative;
          border: 2px solid #a562ff;
          padding: 21px 73px;
          background: none;
          i {
            position: absolute;
            left: 41px;
          }

          &:hover {
            background: rgba(165, 98, 255, 0.1);
            border: 2px solid rgba(165, 98, 255, 0.1);
            color: #a562ff;

            i {
              color: #a562ff;
            }
          }
        }

        &-plug {
          background: inherit;

          background: #a562ff;
          border-radius: 6px;
          padding: 23px 0;

          &:hover {
            background: rgba(165, 98, 255, 0.1);
          }
        }
      }

      &__description {
        color: rgba(226, 223, 250, 0.33);
        margin-right: 0;
      }
    }
  }

  &__title {
    max-width: 811px;
    font-family: "GTAmericaBold", "Roboto Condensed Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 112px;
    line-height: 104px;
    text-align: right;
    text-transform: uppercase;

    color: #ffffff;
  }

  &__text {
    margin-left: auto;
    margin-top: 51px;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 26px;
    text-align: right;
    letter-spacing: 0.2px;

    color: #ffffff;

    &-index {
      display: none;
    }
  }

  &__list {
    margin-top: 76px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    max-width: 514px;
  }

  &__item {
    flex-grow: 1;
    max-width: 246px;

    &:last-child {
      max-width: 240px;
      margin-right: 12px;
    }
  }

  &__description {
    margin-top: 33px;
    margin-right: 12px;
    margin-left: auto;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    text-align: right;
    letter-spacing: -0.2px;

    color: #e2dffa;
  }

  &__router,
  &__btn {
    width: 100%;
    display: block;
    padding: 23px 0;
    text-decoration: none;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;

    letter-spacing: 0.2px;

    background: #40256b;
    border-radius: 6px;
    color: #fff;

    border: none;

    cursor: pointer;

    &-driver {
      background: #38ce97;

      &:hover {
        background: #c5f9e5;
        color: #000;
      }

      &--pota {
        visibility: hidden;
      }
    }

    &-park {
      background: #40256b;

      &:hover {
        background: #e2dffa;
        color: #000;
      }
    }

    &-install {
      background: #005051;
      letter-spacing: -0.5px;

      &:hover {
        background: #c5f9e5;
        color: #000;
      }
    }

    &-bedriver {
      background: #a562ff;
      letter-spacing: -0.5px;

      &:hover {
        background: #c5f9e5;
        color: #000;
      }
    }
  }

  @media (max-width: 600px) {
    background-size: 100%;
    &-driver,
    &-driver-new {
      padding: 25.3vh 16px 2.2vh;
      border-radius: 16px;
      background-image: url("@/assets/img/driver-banner-mobile.png");
      background-repeat: no-repeat;
      min-height: 60vh;
      display: flex;
      flex-direction: column;

      .banner {
        &__router-install {
          letter-spacing: -0.1px;
          /* margin-top: 8.3vh; */
        }
      }
    }

    &-driver-new {
      .banner__title {
        font-size: 42px !important;
      }
    }

    &-index {
      padding: 19vh 16px 2.4vh;
      border-radius: 16px;
      background-image: url("@/assets/img/index-banner.png");
      background-repeat: no-repeat;
      background-position: bottom left -82px;
      min-height: 66vh;
      display: flex;
      flex-direction: column;
      background-size: 138vh;
    }

    &-about {
      padding: 6.2vh 16px 2.2vh;
      border-radius: 16px;
      background-size: 40vh;
      background-image: url("@/assets/img/about-banner-mobile.png");
      min-height: 75vh;
      flex-direction: column;

      .banner__list {
        margin-top: 3.4vh !important;
      }

      .banner__title {
        font-size: 6.2vh;
      }
    }

    &-park {
      padding: 12.9vh 16px 2.2vh;
      border-radius: 16px;
      background-image: url("@/assets/img/park-banner-mobile.png");
      background-repeat: no-repeat;
      min-height: 74vh;
      display: flex;
      flex-direction: column;

      .banner {
        &__btn {
          &-demonstration {
            padding: 12px 0px 12px 36px;

            font-family: "Roboto";
            font-style: normal;
            font-weight: 500;
            font-size: 15px;
            line-height: 19px;

            i {
              font-size: 14px;
              left: 65px;
            }
          }

          &-plug {
            padding: 14px 0;
          }
        }
      }
    }

    .banner {
      &__wrapper {
        margin: 0 auto;
        width: 100%;
      }

      &__title {
        font-weight: 700;
        font-size: 46px;
        line-height: 100%;
        /* letter-spacing: -4.6px; */
      }

      &__text {
        margin-top: 2.5vh;

        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        letter-spacing: 1.7px;
      }

      &__list {
        margin: 0;
        flex-wrap: wrap;
        max-width: 100%;
        margin-top: 4.4vh;
        flex-direction: column;
      }

      &__item {
        max-width: 100%;
        min-width: 100%;
        margin-bottom: 1.8vh;
      }

      &__router {
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        padding: 14px 0;
      }

      &__description {
        margin: 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: center;
        letter-spacing: 0.6px;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    background-size: 138vh;
    &__title {
      font-size: 84px;
      line-height: 78px;
    }
    &-index {
      background-position-x: 0;
      padding: 9.4vw 8vw 3.4vw 0;

      .banner {
        &__title {
          max-width: 692px;
        }

        &__text {
          margin-top: 48px;
        }

        &__list {
          margin-top: 66px;
          max-width: 501px;
        }

        &__item {
          margin-right: 0;
        }

        &__description {
          margin-right: 0;
        }
      }
    }

    &-about {
      padding: 6.4vw 7vw 7.5vw 0;
      background-position: bottom -4.5vw right 1.5vw;
      background-size: 96vw;

      .banner__title {
        max-width: 620px;
      }

      .banner__list {
        margin-top: 3.7vw;
      }
    }

    &-driver,
    &-driver-new {
      padding: 15vw 8vw 3.5vw 0;
      background-size: 128vh;
      background-position-y: 6.4vw;

      .banner {
        &__list {
          margin-top: 71px;
        }

        &__description {
          margin-right: 0;
        }
      }
    }

    &-driver-new {
      padding: 15vw 3vw 3.5vw 0;
      background-size: 150vh;
      background-position-y: 6.1vw;
    }

    &-park {
      padding: 10vw 7vw 3vw 0;
      background-size: 125vh;
      background-position: left -20px bottom;

      .banner {
        &__title {
          max-width: 615px;
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    background-size: 100%;
    &__title {
      font-size: 84px;
      line-height: 78px;
    }
    &-index {
      background-position-x: 0;
      padding: 121px 102px 42px 0;

      .banner {
        &__title {
          max-width: 692px;
        }

        &__text {
          margin-top: 48px;
        }

        &__list {
          margin-top: 66px;
          max-width: 501px;
        }

        &__item {
          margin-right: 0;
        }

        &__description {
          margin-right: 0;
        }
      }
    }

    &-about {
      padding: 81px 90px 97px 0;
      background-position: bottom -51px right 25px;
      background-size: 100%;

      .banner__title {
        max-width: 608px;
      }

      .banner__list {
        margin-top: 47px;
      }
    }

    &-driver,
    &-driver-new {
      padding: 186px 103px 43px 0;
      padding-top: 193px;
      background-size: 93%;
      background-position-x: 30px;

      .banner {
        &__list {
          margin-top: 71px;
        }

        &__description {
          margin-right: 0;
        }
      }
    }

    &-driver-new {
      background-size: 100%;
    }

    &-park {
      padding: 186px 89px 38px 0;
      padding-top: 129px;
      background-size: 91%;
      background-position: left -20px bottom;

      .banner {
        &__title {
          max-width: 615px;
        }
      }
    }
  }
}
</style>
