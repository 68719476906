<template>
  <div class="service-app">
    <ul class="service-app__list">
      <li
        :class="`service-app__item service-app__item-${page}`"
        v-for="card in cardsForPage"
        :key="card.name"
        style="position: relative;"
      >
        <div
          class="service-app__wrapper"
          :class="`service-app__wrapper--${card.name}`"
        >
          <div class="service-app__header">
            <div
              class="service-app__img"
              :class="`service-app__img--${card.name}`"
            >
              <img
                class="service-app__logo"
                :src="`static/img/index/logo-${card.name}.png`"
                width="83"
                height="37"
                :alt="`logo ${card.name}`"
              />
            </div>
            <h3 class="service-app__title" v-html="card.title"></h3>
          </div>
          <p class="service-app__text" v-html="card.description">
          </p>
          <div class="service-app__rating rating">
            <p class="rating__number">{{ card.number }}<span v-if="card.symb">{{ card.symb }}</span></p>
            <div class="rating__wrapper">
              <div class="star-rating">
                <div class="back-stars">
                  <i
                    :key="star"
                    v-for="star in starLimit"
                    class="icon-rating"
                    aria-hidden="true"
                  ></i>

                  <div
                    class="front-stars"
                    :style="`width: ${rate(card.number)}%`"
                  >
                    <i
                      :key="star"
                      v-for="star in starLimit"
                      class="icon-rating-filled"
                      aria-hidden="true"
                    ></i>
                  </div>
                </div>
              </div>

              <p class="rating__platform">{{ card.platform }}</p>
            </div>
          </div>
          <ul class="service-app__platforms platforms">
            <li
              class="platforms__item"
              v-for="(platform, index) in platforms[card.name]"
              :key="index"
            >
              <a :href="platform.href" target="_blank">
                <i :class="`icon-${platform.icon}`"></i>
              </a>
            </li>
          </ul>
          <p class="service-app__description">
            Доступно на Android, iOS{{ card.name !== 'park' ? ', Huawei' : ''}}
          </p>
          <p v-if="card.inform" style="position: absolute; bottom: 20px;" class="service-app__description">
            {{ card.inform }}
          </p>
          <button
            class="service-app__btn"
            @click="isMoreOpen[card.name] = !isMoreOpen[card.name]"
          >
            <!-- <i :class="[isMoreOpen[card.name] ? 'icon-minus' : 'icon-plus']"></i
            >ЕЩЕ -->
          </button>
        </div>
      </li>
    </ul>
    <div class="slider">
      <agile
        :slidesToShow="1.1"
        :navButtons="false"
        :dots="false"
        :unagile="isAgile"
      >
        <div
          class="slider-item"
          :class="`service-app__item service-app__item-${page}`"
          v-for="card in cardsForPage"
          :key="card.name"
        >
          <div
            class="service-app__wrapper"
            :class="`service-app__wrapper--${card.name}`"
          >
            <div class="service-app__header">
              <div
                class="service-app__img"
                :class="`service-app__img--${card.name}`"
              >
                <img
                  class="service-app__logo"
                  :src="`static/img/index/logo-${card.name}.png`"
                  width="83"
                  height="37"
                  :alt="`logo ${card.name}`"
                />
              </div>
              <h3 class="service-app__title" v-html="card.title"></h3>
            </div>
            <p class="service-app__text" v-html="card.description">
            </p>
            <div class="service-app__rating rating">
              <p class="rating__number">{{ card.number }}<span v-if="card.symb">{{ card.symb }}</span></p>
              <div class="rating__wrapper">
                <div class="star-rating">
                  <div class="back-stars">
                    <i
                      :key="star"
                      v-for="star in starLimit"
                      class="icon-rating"
                      aria-hidden="true"
                    ></i>

                    <div
                      class="front-stars"
                      :style="`width: ${rate(card.number)}%`"
                    >
                      <i
                        :key="star"
                        v-for="star in starLimit"
                        class="icon-rating-filled"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </div>
                </div>
                <p class="rating__platform">{{ card.platform }}</p>
              </div>
            </div>
            <ul class="service-app__platforms platforms">
              <li
                class="platforms__item"
                v-for="(platform, index) in platforms[card.name]"
                :key="index"
                style="position: relative;"
              >
                <a :href="platform.href" target="_blank">
                  <i :class="`icon-${platform.icon}`"></i>
                </a>
              </li>
            </ul>
            <p class="service-app__description">
              Доступно на Android, iOS{{ card.name !== 'park' ? ', Huawei' : ''}}
            </p>
            <p v-if="card.inform" style="position: absolute; bottom: 20px; width: 100%;" class="service-app__description">
              {{ card.inform }}
            </p>
            <button
              class="service-app__btn"
              @click="isMoreOpen[card.name] = !isMoreOpen[card.name]"
            >
              <!-- <i
                :class="[isMoreOpen[card.name] ? 'icon-minus' : 'icon-plus']"
              ></i
              >ЕЩЕ -->
            </button>
          </div>
        </div>
      </agile>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueAgile } from "vue-agile";

@Component({
  components: {
    agile: VueAgile,
  },
})
export default class ServiceApp extends Vue {
  @Prop({ type: Number, default: 5 }) starLimit;
  @Prop({ type: String, default: "index" }) page;
  @Prop({ type: Boolean, default: false }) driverNew;

  isMoreOpen = {
    driver: false,
    park: false,
  };

  platforms = {
    driver: [
      {
        icon: "google",
        href: "https://play.google.com/store/apps/details?id=agregator.taxi.fintaxidriver",
      },
      {
        icon: "apple",
        href: "https://apps.apple.com/ru/app/таксиагрегатор/id1477165599",
      },
      {
        icon: "huawei",
        href: "https://appgallery.huawei.com/#/app/C102733117",
      },
    ],
    park: [
      {
        icon: "google",
        href: "https://play.google.com/store/apps/details?id=agregator.taxi.company&hl=ru&gl=US",
      },
      {
        icon: "apple",
        href: "https://apps.apple.com/ru/app/та-бизнес/id1582626134",
      },
      /* {
        icon: "huawei",
        href: "https://appgallery.huawei.com/#/app/C102733117",
      }, */
    ],
  };

  /* cards = [
    {
      name: "driver",
      title: "Водитель TA",
      description: "Деньги в ваших руках",
      number: 4.9,
      platform: "Google Play",
    },
    {
      name: "park",
      title: "Таксопарк TA",
      description: "Виртуальное решение реальных задач",
      number: 4.9,
      platform: "Google Play",
    },
  ]; */

  get isAgile() {
    if (this.page !== "index") return true;
  }

  rate(star) {
    return (star * 100) / this.starLimit;
  }

  get cards() {
    let result = [];
    if (this.driverNew) {
      return [
        {
          name: "driver",
          title: "Таксиагрегатор<br>для водителей",
          description: "Твои деньги <br> в твоих руках",
          number: 4.8,
          symb: '*',
          platform: "Google Play",
          inform: "*На основании Google Play от 25.09.2024",
        },
        {
          name: "park",
          title: "ТА Бизнес",
          description: "Виртуальное решение реальных задач",
          number: 4.7,
          symb: '*',
          platform: "Google Play",
          inform: "*На основании Google Play от 25.09.2024",
        },
      ];
    } else {
      return [
        {
          name: "driver",
          title: "Таксиагрегатор<br>для водителей",
          description: "Деньги в ваших руках",
          number: 4.8,
          symb: '*',
          platform: "Google Play",
          inform: "*На основании Google Play от 25.09.2024",
        },
        {
          name: "park",
          title: "ТА Бизнес",
          description: "Виртуальное решение реальных задач",
          number: 4.7,
          symb: '*',
          platform: "Google Play",
          inform: "*На основании Google Play от 25.09.2024",
        },
      ];
    }
  }

  get cardsForPage() {
    let switcher = {
      driver: 0,
      park: 1,
    };

    let result = [];

    if (this.page === "driver" || this.page === "park") {
      result.push(this.cards[switcher[this.page]]);
    } else {
      result = this.cards;
    }

    return result;
  }
}
</script>

<style lang="scss">
.star-rating {
  display: flex;
  align-items: center;

  font-size: 27px;

  .icon-rating::before,
  .icon-rating-filled::before {
    margin: 0;
    margin-left: 1px;
  }

  @media (max-width: 600px) {
    font-size: 17px;
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    font-size: 2.1vw;
  }
}
.back-stars {
  display: flex;
  position: relative;
}
.front-stars {
  display: flex;
  overflow: hidden;
  position: absolute;
  top: 0;
  transition: all 0.5s;
}
/* .agile__list {
  overflow: inherit;
} */

.agile__dots {
  justify-content: space-between;
}

.agile__actions {
  margin-top: 17px;
  justify-content: center;
}

.agile__dot {
  justify-content: center;

  &:not(:first-child) {
    margin-left: 9px;
  }
  &--current {
    button {
      background: #000 !important;
      border-radius: 10px !important;
      width: 12px !important;
    }
  }
  button {
    border: none;
    background: #a0a0a0;
    border-radius: 10px;
    width: 6px;
    height: 6px;
    padding: 0;
  }
}
</style>

<style lang="scss" scoped>
.platforms {
  display: flex;
  margin-top: 65px;
  max-width: 248px;
  justify-content: space-between;

  &__item {
    font-size: 33px;

    a {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 72px;
      height: 72px;
      border-radius: 50%;
      box-sizing: border-box;
    }
  }

  @media (max-width: 600px) {
    margin-top: 31px;
    max-width: 177px;

    .platforms {
      &__item {
        font-size: 22px;

        a {
          width: 48px;
          height: 48px;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    margin-top: 5.1vw;
    max-width: 19.5vw;

    &__item {
      a {
        width: 5.5vw;
        height: 5.5vw;
      }
    }
  }
}
.rating {
  display: flex;

  &__wrapper {
    margin-left: 33px;
  }

  &__star {
    font-size: 27px;

    .icon-rating::before,
    .icon-rating-filled::before {
      margin: 0;
      margin-left: 1px;
    }
  }

  &__platform {
    margin-top: 6px;

    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    /* letter-spacing: 3px; */

    color: #ffffff;
  }

  &__number {
    display: flex;
    align-items: center;

    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 64px;
    /* letter-spacing: 4px; */

    color: #ffffff;
  }

  /* &__list {
    display: flex;
    flex-wrap: wrap;
  } */

  &__platform {
    width: 100%;
  }

  @media (max-width: 600px) {
    .rating {
      &__number {
        font-weight: 500;
        font-size: 40px;
        line-height: 50px;
      }

      &__platform {
        margin-top: 2px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: left;
      }

      &__wrapper {
        margin-left: 17px;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    &__number {
      font-size: 5vw;
      line-height: 5vw;
    }

    &__platform {
      margin-top: 0.9vw;
      font-size: 2.5vw;
      line-height: 2.5vw;
    }

    &__wrapper {
      margin-left: 2.6vw;
    }
  }
}
.service-app {
  &__list {
    width: 100%;
    display: flex;
  }

  .slider {
    display: none;
  }

  &__item {
    width: 680px;
    &:first-child {
      margin-right: 14px;
    }

    &-park,
    &-driver {
      width: 100%;

      &:first-child {
        margin-right: 0;
      }
    }

    &-park {
      .service-app__wrapper {
        background-image: url("@/assets/img/park-right.png");
        background-position: right 64px bottom;
      }

      .service-app__text {
        max-width: 45%;
      }
    }

    &-driver {
      .service-app__wrapper {
        background-image: url("@/assets/img/driver-right.png");
        background-position: right 62px bottom;
      }
    }
  }

  &__description {
    margin-top: 17px;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: left;
    letter-spacing: 0.2px;

    color: rgba(255, 255, 255, 0.5);
  }

  &__title {
    margin-left: 24px;
    display: flex;
    align-items: center;

    font-family: "GTAmericaEx", "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    /* letter-spacing: 3.5px; */
    text-align: left;

    color: #ffffff;
  }

  &__text {
    font-family: "GTAmericaEx";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    /* letter-spacing: 3.5px; */
    color: #ffffff;
    text-align: left;
    margin-top: 33px;
    flex-grow: 1;
  }

  &__img {
    width: 96px;
    height: 96px;
    border-radius: 26px;
    display: flex;
    align-items: center;
    padding: 7px;
    box-sizing: border-box;

    &--driver {
      background: #003535;
    }

    &--park {
      background: #110033;
    }
  }

  &__header {
    display: flex;
  }

  &__btn {
    margin-top: 17px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84px;
    height: 32px;
    position: relative;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    color: #ffffff;

    border: none;
    background: none;

    /* cursor: pointer; */

    i {
      margin: 0;
      font-size: 32px;
      border-radius: 50%;

      &::before {
        margin: 0;
      }
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    min-height: 800px;
    border-radius: 28px;
    padding: 33px;
    box-sizing: border-box;

    &--driver {
      background: #005051;
      background-image: url("@/assets/img/driver-mid.png");
      background-repeat: no-repeat;

      .service-app__text {
        width: 41%;
      }

      .service-app {
        &__btn {
          i {
            background: #38ce97;
          }
        }
      }

      .front-stars,
      .back-stars {
        color: #38ce97;
      }

      .platforms {
        &__item {
          i {
            color: #38ce97;
          }
          a {
            border: 1px solid #38ce97;
          }

          &:hover {
            i {
              color: #e2dffa;
            }
            a {
              border: 1px solid #e2dffa;
            }
          }
        }
      }
    }

    &--park {
      background: #40256b;
      background-image: url("@/assets/img/park-mid.png");
      background-repeat: no-repeat;

      .service-app__text {
        width: 59%;
      }

      .service-app {
        &__btn {
          i {
            background: #a562ff;
          }
        }
      }

      .front-stars,
      .back-stars {
        color: #a562ff;
      }

      .platforms {
        max-width: 165px;
        &__item {
          i {
            color: #a562ff;
          }
          a {
            border: 1px solid #a562ff;
          }

          &:hover {
            i {
              color: #e2dffa;
            }
            a {
              border: 1px solid #e2dffa;
            }
          }
        }
      }
    }
  }

  @media (max-width: 600px) {
    .slider {
      display: block;
      /* width: 260px; */
      margin: 0;
      overflow: initial;
    }

    .service-app__item-driver {
      .service-app__wrapper--driver {
        background-position: bottom right 0px;
      }
    }

    .service-app {
      margin: 0;
      &__list {
        display: none;
      }

      &__text {
        font-family: "Roboto";
        width: 80%;
      }

      &__item {
        &:first-child {
          margin-right: 0;
        }
      }

      &__wrapper {
        min-height: 428px;
        padding: 13px;
        padding-bottom: 8px;
        border-radius: 16px;
        /* max-width: 265px; */

        &--driver {
          background-image: url("@/assets/img/driver-mid-mobile.png");
          background-repeat: no-repeat;

          background-size: contain;
          background-position: bottom right;
          margin-right: 9px;
        }

        &--park {
          background-image: url("@/assets/img/park-mid-mobile.png");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: bottom 0px right 0;
          margin-right: 9px;

          .service-app__text {
            max-width: 100%;
          }

          .platforms {
            max-width: 105px;
          }
        }
      }

      &__img {
        width: 48px;
        height: 48px;
        border-radius: 11px;
        padding: 4px;
      }

      &__logo {
        width: 42px;
        height: 19px;
      }

      &__title {
        margin-left: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0;
      }

      &__text {
        margin-top: 18px;
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
      }

      &__description {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
      }

      &__btn {
        margin-top: 14px;
        width: 59px;

        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        i {
          font-size: 24px;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    .service-app__item-driver {
      .service-app__wrapper--driver {
        background-size: 52%;
        background-position: bottom right 60px;
      }
    }

    .service-app__item-park {
      .service-app__wrapper--park {
        background-position: bottom 0px right 102px;
        background-size: contain;
      }

      .service-app__title {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .service-app {
      &__description {
        margin-top: 1.5vw;
        font-size: 1.1vw;
      }

      &__logo {
        width: auto;
        height: 3vw;
      }

      &__img {
        width: 7.5vw;
        height: 7.5vw;
        border-radius: 2vw;
      }

      &__item {
        width: 47vw;
        &:first-child {
          margin-right: 1.3vw;
        }

        &-driver,
        &-park {
          width: 100%;
          margin-right: 0 !important;
        }
      }

      &__title {
        font-size: 1.9vw;
        line-height: 1.9vw;
        margin-left: 1.9vw;
      }

      &__text {
        font-family: "GTAmericaEx";
        font-size: 2.5vw;
        margin-top: 2.7vw;

        line-height: 2.8vw;
      }

      &__wrapper {
        padding: 2.5vw;
        min-height: 51.5vw;
        /* background-position: right bottom; */

        &--driver {
          background-position: right -19px bottom -24px;
          background-size: 97%;
          .service-app__text {
            width: 47%;
          }
        }

        &--park {
          background-position: right 32px bottom -8px;
          background-size: 94%;
          .service-app__text {
            width: 67%;
          }

          .platforms {
            max-width: 12.5vw;
          }
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    .service-app__item-driver {
      .service-app__wrapper--driver {
        background-size: 52%;
        background-position: bottom right 60px;
      }
    }

    .service-app__item-park {
      .service-app__wrapper--park {
        background-position: bottom 0px right 102px;
        background-size: contain;
      }

      .service-app__title {
        font-size: 32px;
        line-height: 36px;
      }
    }

    .service-app {
      &__title {
        font-size: 24px;
        line-height: 27px;
      }

      &__text {
        font-family: "GTAmericaEx";
      }

      &__wrapper {
        min-height: 658px;
        /* background-position: right bottom; */

        &--driver {
          background-position: right -19px bottom -24px;
          background-size: 97%;
          .service-app__text {
            width: 47%;
          }
        }

        &--park {
          background-position: right 32px bottom -8px;
          background-size: 94%;
          .service-app__text {
            width: 67%;
          }
        }
      }
    }
  }
}
</style>
