import Vue from "vue";
import VueRouter from "vue-router";
import MainPage from "../views/IndexPage.vue";
import PageNotFound from "../views/404.vue";
import VueFormulate from "@braid/vue-formulate";
import FormulateVSelectPlugin from "@cone2875/vue-formulate-select";

import "vue-select/dist/vue-select.css";

Vue.use(VueFormulate, {
  plugins: [FormulateVSelectPlugin],
});

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: MainPage,
  },
  {
    path: `/driver`,
    name: "driver",
    component: () =>
      import(/* webpackChunkName: "driver" */ "../views/DriverPage.vue"),
  },
  {
    path: "/park",
    name: "park",
    component: () =>
      import(/* webpackChunkName: "park" */ "../views/ParkPage.vue"),
  },
  /* {
    path: "/about",
    name: "about",
    component: () =>
      import( *//* webpackChunkName: "about" */ /* "../views/AboutPage.vue"),
  }, */
  /* {
    path: "/answers",
    name: "answers",
    component: () =>
      import( *//* webpackChunkName: "answers" */ /* "../views/AnswersPage.vue"),
  }, */
  {
    path: "/connect",
    name: "connect",
    component: () =>
      import(/* webpackChunkName: "connect" */ "../views/Connect.vue"),
  },
  {
    path: "/kz",
    redirect: '/connect',
  },
  {
    path: "/driver-new",
    name: "driver-new",
    component: () =>
      import(/* webpackChunkName: "connect" */ "../views/DriverNew.vue"),
  },
  {
    path: "/legal/pota",
    name: "pota",
    component: () =>
      import(/* webpackChunkName: "connect" */ "../views/Pota.vue"),
  },
  { path: "*", name: "404", component: PageNotFound },
];

const router = new VueRouter({
  mode: "history",
  base: '/',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
