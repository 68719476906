<template>
  <div class="menu" :style="`${page === 'connect' ? 'left: 0;' : ''}`">
    <ul class="menu__list">
      <li class="menu__item">
        <ul class="menu__list-first">
          <li
            class="menu__item-first"
            v-for="(one, index) in navigateOne"
            :key="index"
            style="white-space: nowrap;"
          >
            <a
              v-if="one.link"
              class="menu__link"
              :href="one.href"
              @click="showMenu"
              target="_blank"
            >
              {{ one.title }}
            </a>

            <router-link
              class="menu__link"
              v-if="one.router"
              :to="one.href"
              @click.native="showMenu"
              exact
              >{{ one.title }}</router-link
            >
          </li>
        </ul>
      </li>
      <li class="menu__item">
        <ul class="menu__list-second">
          <li
            class="menu__item-second"
            v-for="(two, index) in navigateTwo"
            :key="index"
          >
            <router-link
              class="menu__link"
              v-if="two.router"
              :to="two.href"
              @click.native="showMenu"
              exact
              >{{ two.title }}</router-link
            >
            <a v-else class="menu__link" :href="two.href" target="_blank" @click="showMenu">
              {{ two.title }}
            </a>
          </li>
        </ul>
      </li>
      <li class="menu__item">
        <ul class="menu__list-third">
          <li
            class="menu__item-third"
            v-for="(three, index) in navigateThree"
            :key="index"
          >
            <a
              class="menu__link-icon"
              target="_blank"
              :href="three.href"
              @click="showMenu"
            >
              <i :class="`icon-${three.img}`"></i>
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component()
export default class Menu extends Vue {
  @Prop({ type: String, default: "" }) page;

  navigateOne = [
    { title: "О нас", href: "/", router: true },
    { title: "Скачать", href: "https://fintaxidriver.page.link/driver", link: true },
    { title: "Поддержка", href: "https://help.taxiaggregator.ru/", link: true },
    { title: "Подключить", href: "/connect", router: true },
    { title: "О продукте", href: "/legal/pota", router: true }
  ];

  navigateTwo = [
    { title: "Таксопарк", href: "/park", router: true },
    { title: "Водитель", href: "/driver", router: true },
    { title: "Войти", href: "https://lk.taxiaggregator.ru/login" },
  ];
  navigateThree = [
    { href: "https://www.youtube.com/@taxiaggregator", img: "youtube" },
    { href: "https://t.me/taxiaggregator", img: "telegram" },
  ];

  showMenu() {
    this.$emit("showMenu");
  }
}
</script>

<style lang="scss" scoped>
.menu {
  position: absolute;
  min-height: 421px;
  background: #fff;
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
  min-width: calc(100% - 64px);
  padding: 0 87px 0 87px;
  box-sizing: border-box;
  top: 112px;
  z-index: 500;

  &__btn {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;

    color: #000000;

    border: none;
    background: none;
    padding: 0;

    &:hover {
      color: #a0a0a0;
    }
  }

  &__item {
    width: 28%;
    text-align: left;
    height: inherit;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    &:nth-child(2) {
      width: 35%;
    }

    &:last-child {
      width: auto;
      flex-grow: 1;
    }

    &-first {
      margin-top: 32px;

      &:first-child {
        margin-top: 4px;
      }
    }

    &-second {
      margin-top: 32px;
      &:first-child {
        margin-top: 4px;
      }
    }

    &-third {
      &:last-child {
        margin-top: 49px;
        margin-bottom: 9px;
      }
    }
  }

  &__link {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 36px;
    align-items: center;
    text-decoration: none;

    color: #000000;

    &-icon {
      font-size: 26px;
      color: #000000;
      &:hover {
        color: #a0a0a0;
      }
    }

    &:hover {
      color: #a0a0a0;
    }
  }

  .router-link-active {
    color: #a562ff;
  }

  &__list {
    display: flex;
    min-height: inherit;
    /* padding: 0 87px 0 87px; */
    padding-bottom: 113px;
    box-sizing: border-box;

    &-first,
    &-second {
      border-right: 1px solid rgba(0, 0, 0, 0.1);
    }

    &-first,
    &-second {
      padding-right: 113px;
      padding-left: 113px;

      &:first-child {
        margin-top: 0;
      }
    }

    &-third {
      padding-left: 112px;
    }

    &-first {
      padding-left: 0;
    }
  }

  @media (max-width: 900px) {
    padding: 0 87px 5vh 38px;
    top: 58px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    min-width: calc(100% - 16px);
    min-height: 88vh;
    z-index: 500;
    .menu {
      &__link,
      &__btn {
        font-weight: 500;
        font-size: 24px;
        line-height: 120%;
      }

      &__item {
        width: 100%;

        &-first {
          margin-top: 17px;
        }

        &-second {
          margin-top: 17px;
        }

        &-third {
          margin: 0;
        }
      }

      &__list {
        padding-bottom: 0;
        flex-direction: column;

        &-first {
          padding: 0;
          border: none;
          margin-top: 2.3vh;
        }

        &-second {
          padding: 0;
          border: none;
          margin-top: 2.3vh;
        }

        &-third {
          padding: 0;
          margin-top: 3.3vh;
          border: none;
          display: flex;
          max-width: 105px;
          justify-content: space-between;
          min-height: 12vh;
          align-items: flex-end;
        }
      }
    }
  }
  @media (min-width: 901px) and (max-width: 980px) {
    padding: 0;
    &__link {
      font-size: 26px;
      line-height: 36px;
    }
    min-width: 100%;
  }

  @media (min-width: 981px) and (max-width: 1279px) {
    min-width: 100%;
    padding: 0;
  }
}
</style>
