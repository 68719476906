<template>
  <div :class="`app app-${$router.history.current.name}`">
    <header :class="`header header-${$router.history.current.name}`">
      <nav class="header__nav">
        <router-link
          :to="`${$router.history.current.name === 'driver-new' ? '' : '/'}`"
          class="header__link"
        >
          <img
            class="header__logo"
            :src="`../static/img/logo-${$router.history.current.name}.svg`"
            width="216"
            height="48"
            alt="logo taxi-aggregator"
          />
          Таксиагрегатор
        </router-link>
        <ul
          class="header__list"
          :class="`header__list--${$router.history.current.name}`"
          v-if="$router.history.current.name !== 'driver-new'"
        >
          <li
            class="header__item"
            :class="`header__item-${item.title} header__item-${item.title}--${$router.history.current.name}`"
            v-for="item in navButtons[$router.history.current.name] ||
            navButtons.standart"
            :key="item.title"
          >
            <div class="header__item-wrapper" v-if="item.link">
              <router-link
                class="header__router"
                :to="item.href"
                @click.native="onClickNav(item.href)"
                >{{ item.title }}</router-link
              >
            </div>
            <div v-else>
              <a
                class="header__btn header__link--login"
                v-if="item.title === 'enter'"
                :href="item.href"
                target="_blank"
              >
                <i :class="`icon-${item.icon}`"></i>
              </a>
              <button
                v-else
                class="header__btn"
                :class="{ active: isMenuOpen && item.title === 'menu' }"
                @click.prevent="onClick(item.title)"
              >
                <i :class="`icon-${item.icon}`"></i>
              </button>
            </div>
          </li>
        </ul>
        <a
          v-else
          target="_blank"
          :href="`https://forms.taxiaggregator.ru/market${optionalParams}`"
          class="header__router header__router--driverNew"
          >Получить работу</a
        >
      </nav>
      <Menu
        v-if="isMenuOpen"
        @showMenu="showMenu"
        :page="$router.history.current.name"
      />
    </header>

    <router-view :params="routerParam" :optionalParams="optionalParams" />

    <div :class="`footer footer-${$router.history.current.name}`">
      <div
        class="footer__navigation"
        v-if="
          $router.history.current.name !== 'connect' &&
          $router.history.current.name !== '404'
        "
      >
        <div class="footer__wrapper">
          <router-link
            :to="`${$router.history.current.name === 'driver-new' ? '' : '/'}`"
            class="footer__link"
            style="margin-top: 0"
          >
            <img
              class="header__logo"
              :src="`../static/img/logo-${$router.history.current.name}.svg`"
              width="216"
              height="48"
              alt="logo taxi-aggregator"
            />
            Таксиагрегатор
          </router-link>
          <a class="footer__group-link" href="https://sk.ru" target="_blank">
            <img
              class="footer__logo-group"
              width="289"
              height="96"
              :src="`../static/img/logo-group.svg`"
            />
          </a>
          <p class="copyrights--desktop">
            © ООО «Киви-Технологии»<br class="copyrights--space" />
            Официальный сайт<br class="copyrights--space" />
            Все права защищены<br />
            117405, Г.Москва,<!-- <br /> -->вн.тер.г. Муниципальный Округ
            Чертаново Южное,<!-- <br /> -->ул.Дорожная, д. 60Б
          </p>
        </div>

        <div class="footer__container">
          <ul class="footer__list-site">
            <h4 class="footer__title">Сайт</h4>
            <li
              class="footer__item-site"
              v-for="(btn, index) in siteBtns"
              :key="index"
            >
              <a
                v-if="btn.link"
                class="footer__router"
                :href="btn.href"
                target="_blank"
              >
                {{ btn.title }}
              </a>
              <router-link
                v-else
                :class="`footer__router footer__router--${
                  $router.history.current.name == btn.name ? 'pota' : ''
                }`"
                :to="btn.href"
                @click.native="isMenuOpen = false"
              >
                {{ btn.title }}
              </router-link>
            </li>
          </ul>
          <ul class="footer__list-actions">
            <h4 class="footer__title">Действия</h4>
            <li
              class="footer__item-actions"
              v-for="(action, index) in actions"
              :key="index"
            >
              <!--  <a
                v-if="action.title === `Скачать приложение`"
                class="footer__btn"
                :href="action.href"
                target="_blank"
                >{{ action.title }}</a
              > -->
              <a
                v-if="action.title === `Задать вопрос`"
                class="footer__btn"
                :href="action.href"
                >{{ action.title }}</a
              >
              <a
                v-else
                class="footer__btn"
                :href="action.href"
                target="_blank"
                >{{ action.title }}</a
              >
            </li>
            <li>
              <ul class="footer__list-info footer__list-info--mobile">
                <li class="footer__item-info--mobile">
                  <a
                    v-for="(icon, index) in icons"
                    :key="index"
                    :href="icon.href"
                    class="footer__link-icon"
                    target="_blank"
                  >
                    <i :class="`icon-${icon.img}`"></i>
                  </a>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>

      <ul class="footer__list-info">
        <li class="footer__item-info">
          <a
            v-for="(icon, index) in icons"
            :key="index"
            :href="icon.href"
            class="footer__link-icon"
            target="_blank"
          >
            <i :class="`icon-${icon.img}`"></i>
          </a>
        </li>
        <li class="footer__item-info">
          <p class="footer__description">2024© ТАКСИАГРЕГАТОР</p>
          <ul class="footer__list-documents">
            <li
              class="footer__item-documents"
              v-for="(document, index) in documents"
              :key="index"
            >
              <a
                :href="document.href"
                class="footer__documents-link"
                target="_blank"
                >{{ document.title }}</a
              >
            </li>
          </ul>
        </li>
      </ul>

      <p
        v-if="$router.history.current.name !== 'connect'"
        class="copyrights--mobile"
      >
        © ООО «Киви-Технологии» Официальный сайт Все права защищены<br />
        117405, Г.Москва,<!-- <br /> -->вн.тер.г. Муниципальный Округ Чертаново
        Южное,<!-- <br /> -->ул.Дорожная, д. 60Б
      </p>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import Menu from "@/components/Menu.vue";

@Component({
  components: {
    Menu,
  },
})
export default class App extends Vue {
  documents = [
    /* { title: "Реквизиты", href: "/" }, */
    {
      title: "Договор оферты и конфиденциальность",
      href: "https://lk.taxiaggregator.ru/static/offer.pdf",
    },
    /* { title: "Конфиденциальность", href: "/" }, */
    {
      title: "Личный кабинет",
      href: "https://lk.taxiaggregator.ru/login",
    },
  ];
  icons = [
    { href: "https://t.me/taxiaggregator", img: "telegram" },
    { href: "https://www.youtube.com/@taxiaggregator", img: "youtube" },
  ];

  siteBtns = [
    /* {
      title: "Ответы на вопросы",
      href: "/answers",
    }, */
    { title: "Поддержка", href: "https://help.taxiaggregator.ru/", link: true },
    { title: "Таксопарки", href: "/park" },
    { title: "Водители", href: "/driver" },
    { title: "О продукте", href: "/legal/pota", name: "pota" },
    { title: "Инновационный центр «Сколково»", href: "https://sk.ru", link: true },
  ];

  actions = [
    {
      title: "Скачать приложение",
      href: "https://fintaxidriver.page.link/driver",
    },
    /* { title: "Задать вопрос", href: "#question" }, */
    {
      title: "Войти в личный кабинет",
      href: "https://lk.taxiaggregator.ru/login",
    },
  ];

  navButtons = {
    standart: [
      {
        title: "Водитель",
        link: true,
        href: "/driver",
      },
      {
        title: "Таксопарк",
        link: true,
        href: "/park",
      },
      {
        title: "Подключить",
        link: true,
        href: "/connect",
      },
      {
        title: "enter",
        link: false,
        href: "https://lk.taxiaggregator.ru/login",
        icon: "enter",
      },
      {
        title: "menu",
        link: false,
        icon: "menu",
      },
      {
        title: "geo",
        link: false,
        icon: "geo",
      },
    ],
    pota: [
      {
        title: "Подключить",
        link: true,
        href: "/connect",
      },
      {
        title: "enter",
        link: false,
        href: "https://lk.taxiaggregator.ru/login",
        icon: "enter",
      },
    ],
  };

  routerParam = "";

  optionalParams = "";

  isMenuOpen = false;

  onClick(name) {
    if (name === "menu") {
      this.isMenuOpen = !this.isMenuOpen;
    }
  }

  onClickNav(btn) {
    this.isMenuOpen = false;

    if (btn === "/connect") {
      ym(57052429, "reachGoal", "click_form");
      gtag("event", "click", { event_category: "form", event_action: "click" });
    }
  }

  showMenu() {
    this.isMenuOpen = false;
  }

  get isConnect() {
    if (this.$router.history.current.name === "connect") {
      return true;
    } else {
      return false;
    }
  }

  mounted() {
    if (this.routerParam == "") {
      let path = this.$router.history._startLocation;
      this.routerParam = path.substring(path.indexOf("?"));
    }
    const currentUrl = window.location.href;
    const parsedUrl = new URL(currentUrl);
    const paramsString = parsedUrl.search.substring(1);
    this.optionalParams = paramsString ? `?${paramsString}` : "";
  }
}
</script>

<style lang="scss" scoped>
.app {
  margin: 0 auto;
  max-width: 1440px;
  text-align: center;
  padding: 0 32px;
  box-sizing: border-box;

  &-driver-new {
    .footer {
      min-height: 222px;
    }

    .footer__link {
      max-width: 100%;
      /* justify-content: center; */
    }

    .footer__container {
      display: none;
    }

    .footer__item-documents:last-child {
      display: none;
    }

    .footer__wrapper {
      flex-direction: row;
      flex-wrap: wrap;
      max-width: 100%;
    }

    .footer__logo-group {
      left: 0;

      @media (min-width: 601px) and (max-width: 823px) {
        left: -30px;
      }
    }

    @media (max-width: 600px) {
      .footer {
        min-height: 100%;
      }

      /* .footer__item-info {
        padding-left: 26px !important;
      } */

      .footer__link {
        justify-content: center;
      }
    }
  }

  &-about {
    .footer {
      margin-top: 145px;

      @media (max-width: 900px) {
        margin-top: 65px;
      }

      @media (min-width: 1280px) and (max-width: 1440px) {
        margin-top: 105px;
      }
    }

    /* @media (min-width: 601px) and (max-width: 1279px) {
      padding: 0 2.22vw;
    } */
  }

  &-404 {
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 60vw;
    position: relative;
    background-image: url("@/assets/img/background-404.png");
    background-repeat: no-repeat;
    background-size: 775px;
    background-position: bottom left 14px;
    max-width: 100% !important;

    @media (max-width: 899px) {
      height: 568px;
      background-size: 539px;
      background-position: bottom -7px left -142px;
      max-width: 100% !important;
    }

    @media (min-width: 900px) and (max-width: 1440px) {
      height: 62.5vw;
      background-size: 686px;
      background-position: bottom left 0px;
      max-width: 100% !important;
    }

    .footer {
      margin-top: 33px;
      min-height: initial;
      position: absolute;
      bottom: 0;
      left: 0;
      padding-right: 32px;
      padding-left: 32px;
      width: 100%;

      &__link-icon {
        color: #000;
      }

      &__description {
        color: #a0a0a0;

        @media (max-width: 900px) {
          display: none;
        }
      }

      &__item-documents {
        border-color: #000;
        color: #000;

        @media (max-width: 600px) {
          color: #fff;
        }
      }

      &__item-info {
        @media (max-width: 900px) {
          margin-top: 29px !important;
          padding-left: 31px !important;
        }
      }

      @media (max-width: 900px) {
        margin-top: 33px;
        min-height: initial;
        padding-bottom: 32px !important;
      }
    }
    @media (max-width: 900px) {
      padding-bottom: 42px !important;
    }
  }

  &-connect {
    background: #40256b;
    max-width: 100% !important;
    margin: 0;
    padding: 0;

    .footer {
      max-width: 1440px;
      margin: 0 auto;
      margin-top: 7px;
      min-height: initial;
      padding-left: 32px;
      padding-right: 32px;

      &__list-info {
        z-index: 5;
      }

      &__link-icon {
        color: #fff;
      }

      &__description {
        color: #a562ff;

        @media (max-width: 900px) {
          display: none;
        }
      }

      &__item-documents {
        border-color: #fff;
        color: #fff;
      }

      &__item-info {
        @media (max-width: 900px) {
          /* margin-top: 29px !important; */
          padding-left: 31px !important;
        }
      }

      @media (max-width: 900px) {
        margin-top: 33px;
        min-height: initial;
        padding: 0;
      }
    }
    @media (max-width: 900px) {
      padding-bottom: 42px !important;
    }
  }

  &-driver-new {
    .copyrights--desktop {
      position: initial !important;
    }
  }

  @media (max-width: 900px) {
    padding: 0 8px;
  }

  @media (min-width: 901px) and (max-width: 1440px) {
    max-width: 1280px;
  }
}

.footer {
  margin-top: 182px;
  min-height: 425px;
  padding-bottom: 33px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;

  &__group-link {
    width: fit-content;
  }

  &__logo-group {
    position: relative;
    left: -0.7vw;
    width: 8.5vw;
    max-width: 115px;
    height: auto;

    @media (max-width: 600px) {
      width: 60px;
      left: 0;
    }
  }

  &__wrapper {
    display: flex;
    flex-direction: column;
    max-width: 617px;
    flex-grow: 1;
    justify-content: space-between;

    @media (max-width: 600px) {
      align-items: center;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-around;
    }
  }

  &__description {
    display: flex;
    align-items: center;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    margin-right: 47px;

    color: #a0a0a0;
  }

  .copyrights--mobile {
    display: none;
  }

  .copyrights--desktop {
    display: block;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    color: #a0a0a0;
    text-align: left;
  }

  &__documents {
    &-link {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      text-transform: uppercase;
      text-decoration: none;

      letter-spacing: 0.4px;

      color: inherit;
    }
  }

  &__link {
    font-family: "GTAmericaEx";
    margin-top: 33px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 700;
    font-size: 20px;
    line-height: 100%;
    color: #393635;

    &-icon {
      display: block;
      cursor: pointer;
      color: #000000;

      &:hover {
        color: #a0a0a0;
      }
    }
  }

  &__title {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    text-align: left;

    color: #000000;

    margin-bottom: 30px;
  }

  &__container {
    display: flex;
    flex-grow: 1;
    min-width: 400px;
  }

  &__router,
  &__btn {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-decoration: none;

    color: #000000;

    cursor: pointer;

    &:hover {
      color: #a0a0a0;
    }

    &--pota {
      display: none;
    }
  }

  &__btn {
    margin: 0;
    padding: 0;
    background: none;
    border: none;
  }

  &__navigation {
    display: flex;
    flex-wrap: wrap;
  }

  &__list {
    &-info {
      min-height: 28px;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      &--mobile {
        display: none;
      }
    }

    &-documents {
      display: flex;
      flex-wrap: wrap;
    }

    &-site {
      width: 43%;
      padding-top: 12px;
    }

    &-actions {
      width: 57%;
      padding-top: 12px;
    }
  }

  &__item {
    &-documents {
      display: flex;
      align-items: center;
      margin: 5px 0 5px 0;
      padding: 0 47px 0 47px;
      border-left: 1px solid #000;

      cursor: pointer;

      &:last-child {
        padding-right: 0;
      }

      &:hover {
        color: #a0a0a0;
      }
    }

    &-actions {
      padding-top: 17px;
    }

    &-site {
      padding-top: 17px;
      width: fit-content;
    }

    &-info {
      display: flex;
      align-items: center;
      min-width: 68px;
      justify-content: space-between;
    }
  }

  @media (max-width: 600px) {
    min-height: 100%;
    margin-top: 65px;

    .copyrights--desktop {
      display: none;
    }

    .copyrights--mobile {
      margin-top: 10px;
      display: block;
      text-align: left;
      max-width: 100%;
      font-size: 9px;
      padding-left: 39px;
      padding-right: 39px;
      color: #a0a0a0;
    }

    .footer {
      &__container {
        min-width: 100%;
      }

      &__description {
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 100%;
        letter-spacing: 0.08em;
        text-transform: uppercase;
        margin-bottom: 5px;
      }

      &__documents-link {
        font-style: normal;
        font-weight: 500;
        font-size: 8px;
        line-height: 100%;
        /* identical to box height, or 8px */
        letter-spacing: 0.8px;
        text-transform: uppercase;
      }

      &__list {
        &-info {
          &--mobile {
            display: flex;
            margin-top: 29px;
          }
        }

        &-actions {
          position: relative;
        }

        &-actions,
        &-site {
          width: 50%;
          padding-left: 39px;
          padding-top: 33px;
        }
      }

      &__link {
        margin-top: 13px;
        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        justify-content: center;
        /* letter-spacing: 2px; */
        .header__logo {
          width: 99px;
          height: 22px;
          margin-right: 10px;
        }
      }

      &__router,
      &__btn {
        font-size: 12px;
        text-align: left;
      }

      &__title {
        font-family: "GTAmericaEx";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        color: #000000;
        margin-bottom: 7px;
      }

      &__item {
        &-info {
          margin-top: 62px;
          flex-wrap: wrap;
          width: 100%;
          padding-left: 39px;
          padding-right: 39px;
          &:first-child {
            display: none;
          }

          &--mobile {
            display: flex;
            min-width: 67px;
            justify-content: space-between;
          }
        }

        &-documents {
          padding: 0;
          border: none;

          &:nth-child(even) {
            padding-left: 13px;
            border-left: 1px solid #000000;
          }

          &:nth-child(2) {
            width: 50%;
          }

          &:nth-child(odd) {
            padding-right: 13px;
          }
        }

        &-site,
        &-actions {
          padding-top: 8px;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 823px) {
    .copyrights--mobile {
      display: block;
      color: #a0a0a0;
      text-align: center;
      margin-top: 10px;
      font-size: 12px;
    }

    .copyrights--desktop {
      display: none;
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    margin-top: 65px;

    .footer {
      &__documents {
        &-link {
          font-size: 1.1vw;
          line-height: 1vw;
        }
      }

      &__item {
        &-documents {
          padding: 0 3.6vw 0 3.6vw;

          &:last-child {
            padding-right: 0;
          }
        }

        &-info {
          min-width: 5.4vw;
        }
      }

      &__description {
        margin-right: 3.9vw;
        font-size: 1.1vw;
        line-height: 1vw;
      }

      &__link {
        max-width: 617px;
      }

      &__list-site {
        width: 60%;
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    margin-top: 65px;

    .footer {
      &__link {
        max-width: 617px;
      }

      &__list-site {
        width: 60%;
      }
    }

    &-driver-new {
      margin-top: 114px;

      .footer__link {
        max-width: 100%;
        justify-content: center;
      }
    }
  }
}

/* ---------------------- */

.header {
  padding: 0 32px;
  padding-bottom: 33px;
  position: fixed;
  background: #fff;
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 1440px;
  left: calc(-50vw + 50%);
  right: calc(-50vw + 50%);
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  background: #fff;
  /* backdrop-filter: blur(10px); */
  z-index: 500;

  &__item-geo {
    display: none !important;
  }

  &-connect {
    position: relative;
    margin: 0 auto;
    left: initial;
    right: initial;
    background: #40256b;

    .active {
      background: #a562ff !important;
      border: 2px solid #a562ff !important;
    }

    .header__link {
      color: #fff;
    }

    .header__btn {
      color: #fff;
      border: 2px solid #a562ff;
    }

    .header__router {
      border: 2px solid #a562ff;
      color: #fff;

      &.router-link-exact-active {
        background: #a562ff;
        border-color: #a562ff;
        color: #fff;
      }
    }
  }

  &__nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &__logo {
    margin-right: 16px;
  }

  &__link {
    font-family: "GTAmericaEx";
    font-style: normal;
    margin-top: 33px;
    display: flex;
    align-items: center;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    line-height: 25px;
    color: #393635;

    &--login {
      max-height: 48px;
      box-sizing: border-box;
      color: #000000;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
    margin-top: 33px;
    min-width: 637px;
    list-style: none;

    display: flex;
    justify-content: space-between;

    &--pota {
      min-width: 213px;
    }
  }

  &__item-wrapper {
    height: 100%;
  }

  &__router {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;

    min-height: 47px;
    padding: 12px 32px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    text-decoration: none;

    border: 2px solid #a0a0a0;
    border-radius: 26px;

    color: #000;

    &--driverNew {
      /* width: 166px; */
      min-height: 47px;
      margin-top: 33px;
      /* margin-right: 63px; */
      justify-content: center;
    }

    &:hover {
      background: #e2dffa;
      border-color: #e2dffa;
      color: #000;
    }

    &.router-link-exact-active {
      color: #fff;
      background: #003535;
      border-color: #003535;
    }
  }

  &__btn {
    min-height: 47px;
    display: flex;
    align-items: center;
    padding: 10px 9px;
    border: 2px solid #a0a0a0;
    border-radius: 50%;
    background: inherit;
    font-size: 18px;

    cursor: pointer;

    &:hover {
      background: #e2dffa;
      border-color: #e2dffa;
      color: #000;
    }
  }

  .active {
    color: #fff;
    background: #003535;
    border-color: #003535;
  }

  @media (max-width: 900px) {
    padding: 0 8px;
    padding-bottom: 13px;
    max-width: 100%;

    &-404 {
      left: 0;
    }

    &-connect {
      padding: 0;
    }

    .header {
      &__router {
        &--driverNew {
          /* width: 82px; */
          margin-top: 13px;
          margin-right: 0px;
          justify-content: center;
          font-size: 10px;
          padding: 5px 4px;
          min-height: 0;
        }
      }

      &__logo {
        width: 99px;
        height: 22px;
        margin-right: 10px;
      }

      &__link {
        margin-top: 13px;

        font-weight: 500;
        font-size: 12px;
        line-height: 19px;
        /* letter-spacing: 2px; */
      }

      &__list {
        min-width: inherit;
        margin-top: 13px;
      }

      &__item {
        display: none;

        &-menu {
          margin-right: 8px;
        }

        &-menu,
        &-geo {
          display: block;

          .header__btn {
            min-height: 33px;
            padding: 4px 3px;
            font-size: 16px;
          }
        }

        &-enter--pota {
          display: block;

          .header__btn {
            min-height: 33px;
            padding: 4px 3px;
            font-size: 16px;
            margin-right: 8px;
          }
        }
      }
    }
  }

  @media (min-width: 901px) and (max-width: 1279px) {
    max-width: calc(100% - 64px);
    padding-left: 0px;
    padding-right: 0px;

    &-404 {
      max-width: initial;
      padding-left: 32px;
      padding-right: 32px;
    }

    &__nav {
      flex-wrap: nowrap;
    }

    &__list {
      min-width: 475px;

      &--pota {
        min-width: 175px;
      }
    }

    &__router {
      padding: 12px 11px;
    }

    &__logo {
      width: 155px;
      height: auto;
    }
  }

  @media (min-width: 1281px) and (max-width: 1440px) {
    max-width: 1280px;

    &-404 {
      max-width: initial;
      padding-left: 32px;
      padding-right: 32px;
    }
  }
}
</style>
