<template>
  <div class="social">
    <ul v-if="isSocialVisible" class="social__list">
      <li class="social__item" v-for="(item, index) in listItems" :key="index">
        <div class="social__wrapper">
          <h3 class="social__title">{{ item.title }}</h3>
          <p class="social__text" v-html="item.text"></p>
        </div>
        <router-link
          v-if="!item.link"
          :class="`social__btn social__btn--${page} social__btn--${helper}`"
          :to="item.href"
        >
          <i
            ><svg
              class="dots"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="11" width="4" height="2" fill="white" />
              <rect x="10" y="11" width="4" height="2" fill="white" />
              <rect x="17" y="11" width="4" height="2" fill="white" />
            </svg>
            <svg
              class="arrow"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16"
                cy="16"
                r="16"
                transform="rotate(-180 16 16)"
                fill="#005051"
              />
              <g clip-path="url(#clip0_1987_28302)">
                <path
                  d="M13.0223 14.993H22V16.9883H13.0223V14.993Z"
                  fill="white"
                />
                <path
                  d="M11.0257 15.4095V15.9906V16.5718L12.4386 17.986H13.0223V19.9813H11.6131L9.03038 17.3986V15.9906V14.5827L11.6131 12H13.0223V13.9953H12.4386L11.0257 15.4095Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1987_28302">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 1 28 4)"
                  />
                </clipPath>
              </defs>
            </svg>
          </i>
          Перейти
        </router-link>
        <a
          v-else
          :class="`social__btn social__btn--${page} social__btn--${helper}`"
          :href="item.href"
          target="_blank"
        >
          <i
            ><svg
              class="dots"
              width="32"
              height="32"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect x="3" y="11" width="4" height="2" fill="white" />
              <rect x="10" y="11" width="4" height="2" fill="white" />
              <rect x="17" y="11" width="4" height="2" fill="white" />
            </svg>
            <svg
              class="arrow"
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="16"
                cy="16"
                r="16"
                transform="rotate(-180 16 16)"
                fill="#005051"
              />
              <g clip-path="url(#clip0_1987_28302)">
                <path
                  d="M13.0223 14.993H22V16.9883H13.0223V14.993Z"
                  fill="white"
                />
                <path
                  d="M11.0257 15.4095V15.9906V16.5718L12.4386 17.986H13.0223V19.9813H11.6131L9.03038 17.3986V15.9906V14.5827L11.6131 12H13.0223V13.9953H12.4386L11.0257 15.4095Z"
                  fill="white"
                />
              </g>
              <defs>
                <clipPath id="clip0_1987_28302">
                  <rect
                    width="24"
                    height="24"
                    fill="white"
                    transform="matrix(-1 0 0 1 28 4)"
                  />
                </clipPath>
              </defs>
            </svg>
          </i>
          Перейти
        </a>
      </li>
    </ul>
    <div v-if="isSocialVisible" class="slider">
      <agile :slidesToShow="1" :navButtons="false">
        <div
          class="slider-item social__item"
          v-for="(item, index) in listItems"
          :key="index"
        >
          <div class="social__wrapper">
            <h3 class="social__title">{{ item.title }}</h3>
            <p class="social__text" v-html="item.text">
              <!-- {{ item.text }} -->
            </p>
          </div>
          <router-link
            v-if="!item.link"
            :class="`social__btn social__btn--${page}`"
            :to="item.href"
          >
            <i
              ><svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="3" y="11" width="4" height="2" fill="white" />
                <rect x="10" y="11" width="4" height="2" fill="white" />
                <rect x="17" y="11" width="4" height="2" fill="white" /></svg
            ></i>
            Подробнее
          </router-link>
          <a
            v-else
            :class="`social__btn social__btn--${page}`"
            :href="item.href"
            target="_blank"
          >
            <i
              ><svg
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="3" y="11" width="4" height="2" fill="white" />
                <rect x="10" y="11" width="4" height="2" fill="white" />
                <rect x="17" y="11" width="4" height="2" fill="white" /></svg
            ></i>
            Подробнее
          </a>
        </div>
      </agile>
    </div>
    <TaxiForm v-if="form" :page="page" :driverNew="driverNew"/>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import TaxiForm from "@/components/TaxiForm.vue";
import { VueAgile } from "vue-agile";

@Component({
  components: {
    TaxiForm,
    agile: VueAgile,
  },
})
export default class Social extends Vue {
  @Prop({ type: String, default: "index" }) page;
  @Prop({ type: String, default: "" }) helper;
  @Prop({ type: Boolean, default: true }) form;
  @Prop({type: Boolean, default: true }) isSocialVisible;
  @Prop({ type: Boolean, default: false}) driverNew;

  get listItems() {
    let items = [];
    if (this.page === "index") {
      items.push(
        {
          title: "Телеграм",
          text: "Присоединяйся <br> к нашему каналу",
          link: true,
          href: "https://t.me/taxiaggregator",
        },
        {
          title: "Таксопаркам",
          text: "Если ты управляешь таксопарком идем сюда",
          link: false,
          href: "/park",
        },
        {
          title: "Водителям",
          text: "Если ты водитель <br> идем сюда",
          link: false,
          href: "/driver",
        }
      );
    }
    if (this.page === "driver") {
      items.push(
        {
          title: "Телеграм",
          text: "Новости, розыгрыши, живое общение",
          link: true,
          href: "https://t.me/taxiaggregator",
        },
        {
          title: "Таксопаркам",
          text: "Для тех, кто ведёт бизнес",
          link: false,
          href: "/park",
        }
      );
    }

    if (this.page === "park") {
      items.push(
        {
          title: "Телеграм",
          text: "Новости, розыгрыши и живое общение. Нас уже больше 4000",
          link: true,
          href: "https://t.me/taxiaggregator",
        },
        {
          title: "Водителям",
          text: "Возможности сервиса для водителей",
          link: false,
          href: "/driver",
        }
      );
    }
    return items;
  }
}
</script>

<style lang="scss" scoped>
.social {
  .slider {
    display: none;
  }
  &__list {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 49px;
    column-gap: 16px;
  }

  &__wrapper {
    width: 356px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 322px;
    /*  min-width: 448px; */
    background: #f7f7fd;
    padding: 33px;
    margin: 0 auto;
    margin-bottom: 16px;
    flex-grow: 1;

    border-radius: 28px;

    box-sizing: border-box;
  }

  &__text {
    margin-top: 5px;
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    text-align: left;

    color: #000000;
  }

  &__title {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: left;

    color: #000000;
  }

  &__btn {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84px;
    height: 32px;
    position: relative;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 17px;
    line-height: 20px;
    text-transform: uppercase;
    text-decoration: none;

    color: #000000;

    border: none;
    background: none;

    cursor: pointer;

    .arrow {
      display: none;
    }

    &:hover {
      i {
        background: #c5f9e5;

        .dots {
          display: none;
        }

        .arrow {
          display: block;
          transform: rotate(180deg);
        }

        circle {
          fill: #c5f9e5;
        }
      }
    }

    i {
      margin: 0;
      font-size: 32px;
      border-radius: 50%;
      margin-right: 16px;
      background: #38ce97;
      color: #fff;

      &::before {
        margin: 0;
      }
    }

    &--park,
    &--about {
      &:hover {
        i {
          circle {
            fill: #e2dffa;
          }
        }
      }
      i {
        background: #a562ff;
      }
    }
  }

  @media (max-width: 600px) {
    .slider {
      display: block;
      margin-top: 30px;
      margin-bottom: 36px;
    }

    .social {
      &__list {
        display: none;
      }

      &__title {
        font-family: "GTAmericaEx";
        font-weight: 500;
        font-size: 32px;
        line-height: 40px;
        /* letter-spacing: 4px; */
      }

      &__text {
        font-family: "GTAmericaEx";
        font-weight: 500;
        font-size: 15px;
        line-height: 19px;
        width: 53%;
        /* letter-spacing: 2px; */
      }

      &__item {
        padding: 20px 17px 12px 17px;
        min-height: 204px;
        margin-bottom: 0;
        border-radius: 16px;
      }

      &__btn {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;

        i {
          margin-right: 9px;
          svg {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    .social {
      &__item {
        width: 0;
        border-radius: 2.3vw;
        min-height: 25.3vw;
        padding: 2.6vw;
        margin-bottom: 1.3vw;
      }

      &__title {
        font-size: 2.5vw;
        line-height: 3vw;
      }

      &__text {
        font-size: 1.56vw;
        line-height: 1.9vw;
        margin-top: 0.4vw;
      }

      &__btn {
        height: 2.2vw;
        width: 6.6vw;
        font-size: 1.3vw;
        line-height: 1vw;

        i {
          font-size: 2vw;
          margin-right: 1.3vw;

          svg {
            width: 2.6vw;
            height: auto;
          }
        }
      }

      &__wrapper {
        width: 100%;
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    .social {
      &__item {
        width: 32%;
      }

      &__title {
        font-size: 32px;
        line-height: 36px;
      }

      &__text {
        font-size: 20px;
        line-height: 25px;
      }

      &__wrapper {
        width: 100%;
      }
    }
  }
}
</style>
