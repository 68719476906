<template>
  <div class="main-page">
    <Banner />

    <h2 class="main-page__title">
      СЕРВИС ДЛЯ
      <span class="main-page__title--green">ВОДИТЕЛЕЙ</span> И&nbsp;<span
        class="main-page__title--purple"
        >ТАКСОПАРКОВ</span
      >
    </h2>
    <p class="main-page__description">
      <span class="main-page__description--spacebar">&nbsp;</span>Все ваши
      задачи в одном месте: от моментальных выплат до аналитики эффективности
      таксопарка.
    </p>
    <p class="main-page__description-mobile" style="margin-bottom: 33px">
      <span class="main-page__description-mobile--spacebar">&nbsp;</span>Все
      ваши задачи в одном месте: от моментальных выплат до аналитики
      эффективности таксопарка.
    </p>
    <ServiceApp />
    <h2 class="main-page__title main-page__title--mobile">Нам доверяют</h2>
    <WithUs />
    <h2 class="main-page__title main-page__title--write">О нас пишут</h2>
    <Write />
    <h2 class="main-page__title main-page__title--who">Кто мы?</h2>
    <AboutWe />
    <h2 class="main-page__title main-page__title--why">Почему мы?</h2>
    <WhyWe />
    <h2 class="main-page__title main-page__title--functionality">Функционал</h2>
    <Functionality />
    <h2 class="main-page__title main-page__title--telegramm">
      В нашей
      <span class="main-page__title--purple">телеге</span><br />
      лучшие&nbsp;новости
    </h2>
    <Social />
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import Banner from "@/components/Banner.vue";
import ServiceApp from "@/components/ServiceApp.vue";
import WithUs from "@/components/WithUs.vue";
import Write from "@/components/Write.vue";
import AboutWe from "@/components/AboutWe.vue";
import WhyWe from "@/components/WhyWe.vue";
import Functionality from "@/components/Functionality.vue";
import Social from "@/components/Social.vue";
import TaxiForm from "@/components/TaxiForm.vue";

@Component({
  components: {
    Banner,
    ServiceApp,
    WithUs,
    Write,
    AboutWe,
    WhyWe,
    Functionality,
    Social,
    TaxiForm,
  },
})
export default class MainPage extends Vue {
  @Prop({ type: String, default: "index" }) page;

  created() {
    document
      .querySelector('meta[name~="description"]')
      .setAttribute(
        "content",
        "Моментальные выплаты для водителей и таксопарков. Инструменты для роста бизнеса: поиск водителей, антифрод система, управление таксопарком."
      );

      document
      .querySelector('meta[name~="keywords"]')
      .setAttribute(
        "content",
        "моментальные выплаты такси, таксиагрегатор, открыть таксопарк, работа в такси, водитель такси, антифрод такси, выплаты для таксопарка, моментальные выплаты для таксопарков, приложение таксиагрегатор"
      );

    document.querySelector("title").textContent =
      "Таксиагрегатор – сервис моментальных выплат для водителей и таксопарков";
  }
}
</script>

<style lang="scss" scoped>
.main-page {
  padding-top: 114px;
  &__title {
    margin: 0;
    margin-top: 173px;
    margin-bottom: 65px;
    font-family: "GTAmericaBold", "Roboto Condensed Bold";
    font-style: normal;
    font-weight: 700;
    font-size: 112px;
    line-height: 104px;
    text-transform: uppercase;
    color: #000000;
    text-align: left;

    &--green {
      color: #38ce97;
    }

    &--purple {
      color: #a562ff;
    }

    &--write {
      margin-bottom: 50px;
    }

    &--why {
      margin-bottom: 65px;
    }
  }

  &__description {
    display: block;
    margin: 0;
    margin-left: 87px;
    margin-bottom: 65px;
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 56px;
    line-height: 60px;
    text-align: left;
    /* letter-spacing: 6px; */

    color: #000000;

    &-mobile {
      display: none;
    }

    &--spacebar {
      padding-left: 163px;
    }
  }

  @media (max-width: 600px) {
    padding-top: 59px;
    .main-page {
      &__title {
        font-weight: 700;
        font-size: 46px;
        line-height: 100%;
        margin-top: 65px;
        margin-bottom: 17px;

        &--mobile {
          margin-top: 43px;
        }

        &--write {
          margin-bottom: 33px;
        }

        &--why {
          margin-bottom: 30px;
        }

        &--functionality {
          margin-bottom: 33px;
        }
      }

      &__description {
        display: none;

        &-mobile {
          display: block;
          font-weight: 500;
          font-size: 24px;
          line-height: 120%;
          display: block;
          font-family: "Roboto Medium";
          font-style: normal;
          font-weight: 500;
          text-align: left;
          /* letter-spacing: 1px; */
          margin-left: 40px;

          color: #000000;

          &--spacebar {
            padding-left: 21px;
          }
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    padding-top: 10vw;
    .main-page {
      &__title {
        margin-top: 10.5vw;
        margin-bottom: 3.8vw;
        font-size: 6.6vw;
        line-height: 6.1vw;

        &--mobile {
          margin-top: 7.4vw;
        }

        &--write {
          margin-top: 7vw;
          margin-bottom: 3.2vw;
        }

        &--who {
          margin-top: 7.7vw;
          margin-bottom: 5.2vw;
        }

        &--why {
          margin-top: 7.4vw;
          margin-bottom: 5.2vw;
        }

        &--functionality {
          margin-top: 8.5vw;
          margin-bottom: 4.4vw;
        }

        &--telegramm {
          margin-top: 8vw;
          margin-bottom: 5.1vw;
        }
      }

      &__description {
        font-size: 3.3vw;
        line-height: 3.6vw;
        padding-right: 1vw;
        margin-bottom: 7.2vw;
        margin-left: 8vw;

        &--spacebar {
          padding-left: 10.5vw;
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    .main-page {
      &__title {
        margin-top: 135px;
        margin-bottom: 47px;
        font-size: 84px;
        line-height: 78px;

        &--mobile {
          margin-top: 94px;
        }

        &--write {
          margin-top: 96px;
          margin-bottom: 41px;
        }

        &--who {
          margin-top: 99px;
          margin-bottom: 65px;
        }

        &--why {
          margin-top: 94px;
          margin-bottom: 65px;
        }

        &--functionality {
          margin-top: 111px;
          margin-bottom: 57px;
        }

        &--telegramm {
          margin-top: 103px;
          margin-bottom: 65px;
        }
      }

      &__description {
        padding-right: 20px;
        margin-bottom: 95px;
        margin-left: 103px;
        font-size: 42px;
        line-height: 46px;

        &--spacebar {
          padding-left: 135px;
        }
      }
    }
  }
}
</style>
