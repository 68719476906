<template>
  <div class="write">
    <ul class="write__list">
      <li class="write__item" v-for="(logo, index) in logoLimit" :key="index">
        <img
          class="write__logo"
          :src="`static/img/logo${logo}.png`"
          width="332"
          height="200"
          alt="logo writers"
        />
      </li>
    </ul>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component()
export default class WithUs extends Vue {
  logoLimit = 4;
}
</script>

<style lang="scss" scoped>
.write {
  &__list {
    display: flex;
    justify-content: center;
    gap: 16px;
  }

  &__item {
    margin: 16px 0 0 0;
    /* background: #F7F7FD; */
    border-radius: 28px;
  }

  @media (max-width: 600px) {
    .write {
      &__logo {
        width: 100%;
        height: 90px;
      }

      &__list {
        gap: 7px;
        flex-wrap: wrap;
      }

      &__item {
        margin: 0;
        width: 48%;
        border-radius: 12px;
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1440px) {
    .write {
      &__logo {
        width: 100%;
        height: auto;
      }
    }
  }
}
</style>
