var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"taxi-form",class:_vm.mailing ? `taxi-form--${_vm.page}` : `information information--${_vm.page}`},[_c('div',{staticClass:"taxi-form__wrapper"},[_c('h3',{staticClass:"taxi-form__title"},[_vm._v(" "+_vm._s(_vm.mailing ? _vm.formMailings[_vm.page].title : _vm.formInformation[_vm.page].title)+" ")]),_c('p',{staticClass:"taxi-form__text"},[_vm._v(" "+_vm._s(_vm.mailing ? _vm.formMailings[_vm.page].text : _vm.formInformation[_vm.page].text)+" ")])]),(_vm.mailing)?_c('FormulateForm',{staticClass:"taxi-form__form",attrs:{"autocomplete":"off"},on:{"submit":_vm.onSubmit}},[_c('div',[_c('FormulateInput',{class:`input__email input__email--${_vm.page}`,attrs:{"name":"email","type":"text","validation":"^required|email","placeholder":"Ваша эл. почта","validation-messages":{
          required: 'Это поле обязательно',
          email: 'Введите реальный email',
        },"autocomplete":"nope"},model:{value:(_vm.formMailings[_vm.page].email),callback:function ($$v) {_vm.$set(_vm.formMailings[_vm.page], "email", $$v)},expression:"formMailings[page].email"}}),_c('FormulateInput',{class:`input__checkbox input__checkbox--${_vm.page}`,attrs:{"name":"checkbox","type":"checkbox","label":_vm.labelCheckbox,"validation":"required","validation-messages":{
          required: 'Ваше согласие обязательно',
        }},scopedSlots:_vm._u([{key:"label",fn:function({ label, id }){return [_c('label',{staticClass:"formulate-input-label",attrs:{"for":id},domProps:{"innerHTML":_vm._s(label)}})]}}],null,false,1134458586),model:{value:(_vm.formMailings[_vm.page].checkbox),callback:function ($$v) {_vm.$set(_vm.formMailings[_vm.page], "checkbox", $$v)},expression:"formMailings[page].checkbox"}})],1),(!_vm.text)?_c('button',{staticClass:"taxi-form__btn"},[_c('i',[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"3","y":"11","width":"4","height":"2","fill":"white"}}),_c('rect',{attrs:{"x":"10","y":"11","width":"4","height":"2","fill":"white"}}),_c('rect',{attrs:{"x":"17","y":"11","width":"4","height":"2","fill":"white"}})])]),_vm._v("ПОДПИСАТЬCЯ НА РАССЫЛКУ ")]):_c('button',{staticClass:"taxi-form__btn",attrs:{"disabled":""}},[_c('i',[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"3","y":"11","width":"4","height":"2","fill":"white"}}),_c('rect',{attrs:{"x":"10","y":"11","width":"4","height":"2","fill":"white"}}),_c('rect',{attrs:{"x":"17","y":"11","width":"4","height":"2","fill":"white"}})])]),_vm._v(_vm._s(_vm.text)+" ")])]):_vm._e(),(!_vm.mailing)?_c('FormulateForm',{staticClass:"taxi-form__form",attrs:{"autocomplete":"off"},on:{"submit":_vm.onSubmit}},[_c('div',[_c('FormulateInput',{class:`input__name input__name-information--${_vm.page}`,attrs:{"name":"name","type":"text","label":"Как вас зовут?","validation":"^required|matches:/^[а-яА-Яa-zA-ZёЁ ]+$/","validation-messages":{
          required: 'Это поле обязательно',
          matches: 'Необходимо указать имя верно',
        },"autocomplete":"off"},model:{value:(_vm.formInformation[_vm.page].name),callback:function ($$v) {_vm.$set(_vm.formInformation[_vm.page], "name", $$v)},expression:"formInformation[page].name"}}),_c('FormulateInput',{class:`input__email input__email-information--${_vm.page}`,attrs:{"name":"company","type":"text","placeholder":"Название компании","validation":"^required","validation-messages":{
          required: 'Это поле обязательно',
          matches: 'Необходимо указать имя верно',
        },"autocomplete":"off"},model:{value:(_vm.formInformation[_vm.page].company),callback:function ($$v) {_vm.$set(_vm.formInformation[_vm.page], "company", $$v)},expression:"formInformation[page].company"}}),_c('FormulateInput',{class:`input__email input__email-information--${_vm.page}`,attrs:{"name":"email","type":"text","validation":"^required|email","placeholder":"Ваша эл. почта","validation-messages":{
          required: 'Это поле обязательно',
          email: 'Введите реальный email',
        },"autocomplete":"nope"},model:{value:(_vm.formInformation[_vm.page].email),callback:function ($$v) {_vm.$set(_vm.formInformation[_vm.page], "email", $$v)},expression:"formInformation[page].email"}}),_c('FormulateInput',{directives:[{name:"mask",rawName:"v-mask",value:(`8(###)###-##-##`),expression:"`8(###)###-##-##`"}],class:`input__phone input__phone-information--${_vm.page}`,attrs:{"name":"phone","type":"tel","placeholder":"Ваш моб. телефон","validation":"^required|min:11,length","validation-messages":{
          required: 'Это поле обязательно',
          min: 'Введите номер верно',
        },"autocomplete":"nope"},model:{value:(_vm.formInformation[_vm.page].phone),callback:function ($$v) {_vm.$set(_vm.formInformation[_vm.page], "phone", $$v)},expression:"formInformation[page].phone"}}),_c('FormulateInput',{class:`input__checkbox input__checkbox-information--${_vm.page}`,attrs:{"name":"checkbox","type":"checkbox","label":_vm.labelCheckbox,"validation":"required","validation-messages":{
          required: 'Ваше согласие обязательно',
        }},scopedSlots:_vm._u([{key:"label",fn:function({ label, id }){return [_c('label',{staticClass:"formulate-input-label",attrs:{"for":id},domProps:{"innerHTML":_vm._s(label)}})]}}],null,false,1134458586),model:{value:(_vm.formInformation[_vm.page].checkbox),callback:function ($$v) {_vm.$set(_vm.formInformation[_vm.page], "checkbox", $$v)},expression:"formInformation[page].checkbox"}})],1),(!_vm.loading)?_c('button',{staticClass:"taxi-form__btn"},[_c('i',[_c('svg',{attrs:{"width":"32","height":"32","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('rect',{attrs:{"x":"3","y":"11","width":"4","height":"2","fill":"white"}}),_c('rect',{attrs:{"x":"10","y":"11","width":"4","height":"2","fill":"white"}}),_c('rect',{attrs:{"x":"17","y":"11","width":"4","height":"2","fill":"white"}})])]),_vm._v("Отправить ")]):_c('button',{staticClass:"taxi-form__btn"},[_vm._v("Отправка...")])]):_vm._e(),(_vm.dialog)?_c('div',{staticClass:"dialog"},[_vm._m(0)]):_vm._e()],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dialog__window"},[_c('div',{staticClass:"dialog__wrapper"},[_c('h2',{staticClass:"dialog__thx"},[_vm._v("Спасибо!")]),_c('p',{staticClass:"dialog__text"},[_vm._v("Данные успешно отправлены.")])]),_c('img',{staticClass:"dialog__img",attrs:{"width":"212","src":require("@/assets/img/dialog.png")}})])
}]

export { render, staticRenderFns }