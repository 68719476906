<template>
  <div class="about-we">
    <p class="about-we__text">
      <span class="about-we__text--spacebar">&nbsp;</span> Таксиагрегатор -
      единый сервис для решения повседневных задач таксопарков и водителей с
      помощью цифровых технологий. Работа в такси должна быть честной, удобной и
      безопасной
    </p>
    <ul class="about-we__list">
      <li class="about-we__item" v-for="(item, index) in items" :key="index">
        <div class="about-we__wrapper">
          <span class="about-we__text-start" v-if="item.unit === '%'">до</span>
          <p class="about-we__text-number">{{ item.amount }}</p>
          <p class="about-we__text-unit">{{ item.unit }}</p>
        </div>
        <p class="about-we__text-description">{{ item.text }}</p>
      </li>
    </ul>

    <ul class="about-we__list-application application">
      <li
        class="application__item"
        :class="`application__item-${btn.name}`"
        v-for="btn in btns"
        :key="btn.id"
      >
        <div
          class="application__logo"
          :class="`application__logo-${btn.name}`"
        ></div>
        <router-link
          class="application__router"
          :class="`application__router-${btn.name}`"
          :to="btn.href"
          >{{ btn.title }}</router-link
        >
      </li>
      <p class="about-we__description">Доступно на iOS, Android, Browser</p>
    </ul>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";

@Component()
export default class AboutWe extends Vue {
  items = [
    {
      amount: "0.0",
      unit: "руб.",
      text: "обслуживание",
    },
    /* {
      amount: "0.0",
      unit: "%",
      text: "вывод на карту Таксиагрегатор",
    }, */
    {
      amount: "0.8",
      unit: "%",
      text: "вывод на остальные карты",
    },
    /* {
      amount: "0.1",
      unit: "%",
      text: "переводы на агентский счет QIWI",
    }, */
  ];
  btns = [
    {
      id: 1,
      name: "driver",
      title: "Для водителей",
      href: "/driver",
    },
    {
      id: 2,
      name: "park",
      title: "Для таксопарков",
      href: "/park",
    },
  ];
}
</script>

<style lang="scss" scoped>
.application {
  position: relative;
  margin-top: 16px;
  min-height: 247px;
  background: #38ce97;
  border-radius: 28px;
  display: flex;

  &__logo {
    position: absolute;
    top: 0;
    width: 25%;
    height: 100%;
    border-radius: 28px;

    &-driver {
      left: 0;
      background-image: url("@/assets/img/head-driver.png");
      background-repeat: no-repeat;
      background-position: center;
    }

    &-park {
      right: 0;
      background-image: url("@/assets/img/head-park.png");
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  &__router {
    z-index: 500;
    display: block;
    padding: 23px 0;
    margin-top: 100px;
    max-width: 262px;
    text-decoration: none;

    font-family: "Roboto";
    font-style: normal;
    font-size: 16px;

    background: #40256b;
    border-radius: 6px;
    color: #fff;

    &-driver {
      background: #005051;
      margin-left: auto;
      margin-right: 8px;

      &:hover {
        background: #c5f9e5;
        color: #000;
      }
    }

    &-park {
      background: #a562ff;
      margin-right: auto;
      margin-left: 8px;

      &:hover {
        background: #e2dffa;
        color: #000;
      }
    }
  }

  &__item {
    width: 50%;
    overflow: hidden;
  }

  @media (max-width: 600px) {
    margin-top: 33px;
    border-radius: 16px;
    min-height: 304px;
    .application {
      &__item {
        &-park {
          background-image: url("@/assets/img/head-park-mobile.png");
          border-bottom-left-radius: 16px;
          background-position-x: left;
          background-repeat: no-repeat;
          order: 1;
        }

        &-driver {
          background-image: url("@/assets/img/head-driver-mobile.png");
          background-position-x: right;
          border-bottom-right-radius: 16px;
          background-repeat: no-repeat;
          order: 2;
        }
      }

      &__logo {
        display: none;
      }

      &__router {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        padding: 14px 0;

        &-park {
          position: absolute;
          margin-left: 0;
          width: 90%;
          max-width: 100%;
          left: 5%;
          margin-top: 153px;
          z-index: 250;
        }

        &-driver {
          margin-left: 0;
          position: absolute;
          width: 90%;
          max-width: 100%;
          left: 5%;
          margin-top: 212px;
          z-index: 250;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    margin-top: 5.1vw;
    overflow: hidden;

    &__logo {
      border-radius: 0;
      width: 100%;

      &-driver,
      &-park {
        display: none;
      }
    }

    &__item {
      &-driver {
        right: 0;
        background-image: url("@/assets/img/head-driver.png");
        background-repeat: no-repeat;
        background-position: center;
        background-position: center left -52px;
      }

      &-park {
        right: 0;
        background-image: url("@/assets/img/head-park.png");
        background-repeat: no-repeat;
        background-position: center;
        background-position: center right 20px;
      }
    }
  }
}
.about-we {
  &__description {
    position: absolute;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    bottom: 31px;
    right: 42%;
    color: #fff;
    letter-spacing: 0.3px;
  }

  &__list {
    display: flex;
    justify-content: space-around;
    margin-top: 63px;
    /* padding-left: 77px; */
    flex-wrap: wrap;
  }

  &__wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    height: 100%;
  }

  &__text {
    padding-left: 88px;
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    /* letter-spacing: 6px; */
    text-align: left;

    color: #000000;

    &--spacebar {
      padding-left: 138px;
    }

    &-number {
      font-family: "GTAmericaBold", "Roboto Condensed Bold";
      font-weight: 700;
      font-size: 112px;
      line-height: 104px;
      text-transform: uppercase;

      color: #000000;
    }

    &-description {
      margin-top: 17px;
      font-family: "GTAmericaEx", "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 25px;
      line-height: 30px;
      text-align: center;
      width: 98%;

      color: #000000;
    }

    &-unit {
      font-family: "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      /* font-size: 2vw;
      line-height: 3vw;
      margin-left: 0.5vw;
      margin-bottom: 0.2vw; */

      font-size: 28px;
      line-height: 36px;
      margin-left: 8px;
      margin-bottom: 5px;

      color: #000000;
    }

    &-start {
      font-family: "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 28px;
      line-height: 27px;
      margin-right: 7px;
      margin-bottom: 7px;

      color: #000000;
    }
  }

  &__item {
    width: 25%;
    height: 100%;
    margin-bottom: 48px;

    &:last-child {
      margin-bottom: 48px;
    }

    &:not(:first-child) {
      .about-we__text-unit {
        /* font-size: 4vw;
        margin: 0;
        margin-bottom: 0.2vw;
        margin-left: 0.7vw;
        line-height: 5vw; */
        font-family: "GTAmericaEx", "Roboto Medium";
        font-size: 48px;
        line-height: 60px;
        margin-left: 11px;
        margin-bottom: 4px;
      }
    }
  }

  @media (max-width: 600px) {
    .about-we {
      &__list {
        margin-top: 34px;
        padding: 0 12vw;
        row-gap: 33px;
      }

      &__item {
        min-width: inherit;
        margin: 0;
        width: 50%;

        &:not(:first-child) {
          .about-we__text-unit {
            font-weight: 500;
            font-size: 24px;
            line-height: 120%;
            margin-bottom: 0;
          }
        }

        /* &:nth-child(even) {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        } */
      }

      &__description {
        font-weight: 500;
        font-size: 12px;
        line-height: 18px;
        bottom: 14px;
        right: 0;
        min-width: 100%;
      }

      &__text {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        padding: 0 12vw;
        letter-spacing: 0;
        margin-top: 33px;

        &-number {
          font-weight: 700;
          font-size: 46px;
          line-height: 100%;
        }

        &-unit {
          margin-left: 9px;
          font-weight: 500;
          font-size: 16px;
          line-height: 20px;
          letter-spacing: 0;
        }

        &-start {
          font-weight: 500;
          font-size: 20px;
          line-height: 20px;
          margin-right: 1px;
          margin-bottom: 2px;
        }

        &-description {
          margin-top: 4px;
          font-weight: 500;
          font-size: 12px;
          line-height: 15px;
          letter-spacing: -0.7px;
        }

        &--spacebar {
          display: none;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    .about-we {
      &__text {
        padding-left: 8.1vw;
        padding-right: 7vw;
        font-size: 3.3vw;
        line-height: 3.6vw;

        &--spacebar {
          padding-left: 10.5vw;
        }

        &-description {
          font-size: 24px;
        }

        &-number {
          font-size: 8.7vw;
          line-height: 8vw;
        }

        &-start {
          font-size: 2vw;
          line-height: 2.1vw;
          margin-right: 1vw;
          margin-bottom: 0.6vw;
        }

        &-description {
          margin-top: 1.4vw;
          font-size: 1.9vw;
          line-height: 2.3vw;
        }

        &-unit {
          font-size: 2vw;
          line-height: 3vw;
          margin-left: 0.5vw;
          margin-bottom: 0.2vw;
        }
      }

      &__list {
        margin-top: 1.4vw;
        /* padding-left: 7.7vw; */
      }

      &__item {
        /* min-width: 30.8vw;
        height: initial;

        &:last-child {
          min-width: 22vw;
        } */

        &:not(:first-child) {
          .about-we__text-unit {
            font-size: 4vw;
            margin: 0;
            margin-bottom: 0.2vw;
            margin-left: 0.7vw;
            line-height: 5vw;
            font-family: "GTAmericaEx", "Roboto Medium";
          }
        }
      }

      &__description {
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        text-align: center;
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    .about-we {
      &__text {
        padding-left: 105px;
        padding-right: 90px;
        font-size: 42px;
        line-height: 46px;

        &--spacebar {
          padding-left: 133px;
        }

        &-description {
          font-size: 24px;
        }
      }

      &__list {
        margin-top: 65px;
        /* padding-left: 97px; */
      }

      /* &__item {
        min-width: 394px;

        &:last-child {
          min-width: 283px;
        }
      } */
    }
  }
}
</style>
