<template>
  <div class="functionality" :class="`functionality--${page}`">
    <!-- <ul class="functionality__list"> -->
    <transition-group name="flip" tag="ul" class="functionality__list">
      <li
        class="functionality__item"
        :class="`functionality__item${item.id}`"
        :style="`grid-area: i${item.id}; background-image: ${
          item.isOpen ? backgroundImg(item.image?.[page]) : ''
        }`"
        v-for="item in items"
        :key="item.isOpen"
      >
        <!-- <transition name="flip"> -->
        <div class="functionality__wrapper">
          <h3
            class="functionality__title"
            v-html="
              !item.isOpen ? item.text[page].back.title : item.text[page].front
            "
          ></h3>
          <img
            v-if="item.icon && item.isOpen"
            class="functionality__logo"
            :src="`static/img/cards/card-${item.icon[page]}.png`"
            :alt="`logo taxi-aggregator`"
          />
          <p
            class="functionality__text"
            v-if="!item.isOpen"
            v-html="item.text[page].back.text"
          >
            <!-- {{ item.text[page].back.text }} -->
          </p>
          <button
            class="functionality__btn"
            @click="toggleCard(item)"
            :style="`${!item.isOpen ? `margin-top: initial;` : ``}`"
          >
            <i
              ><svg
                v-if="item.isOpen"
                width="32"
                height="32"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="3" y="11" width="4" height="2" fill="white" />
                <rect x="10" y="11" width="4" height="2" fill="white" />
                <rect x="17" y="11" width="4" height="2" fill="white" />
              </svg>
              <svg
                v-else
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="16"
                  cy="16"
                  r="16"
                  transform="rotate(-180 16 16)"
                  fill="#005051"
                />
                <g clip-path="url(#clip0_1979_28243)">
                  <path
                    d="M13.0223 14.993H22V16.9883H13.0223V14.993Z"
                    fill="white"
                  />
                  <path
                    d="M11.0257 15.4095V15.9906V16.5718L12.4386 17.986H13.0223V19.9813H11.6131L9.03038 17.3986V15.9906V14.5827L11.6131 12H13.0223V13.9953H12.4386L11.0257 15.4095Z"
                    fill="white"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1979_28243">
                    <rect
                      width="24"
                      height="24"
                      fill="white"
                      transform="matrix(-1 0 0 1 28 4)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </i>
            {{ !item.isOpen ? "" : "Подробнее" }}
          </button>
        </div>
        <!-- </transition> -->
      </li>
    </transition-group>
    <!-- </ul> -->
    <div class="slider">
      <agile :slidesToShow="1" :navButtons="false">
        <div
          class="slider-wrapper"
          v-for="(sliders, index) in sliderList"
          :key="index"
        >
          <div
            class="slider-item"
            :class="`functionality__item functionality__item${
              items[item - 1].id
            }`"
            :style="`grid-area: i${items[item - 1].id}; background-image: ${
              items[item - 1].isOpen
                ? backgroundImg(items[item - 1].image?.[page])
                : ``
            }`"
            v-for="item in sliders"
            :key="items[item - 1].id"
          >
            <div class="functionality__wrapper">
              <h3
                class="functionality__title"
                v-html="
                  !items[item - 1].isOpen
                    ? items[item - 1].text[page].back.title
                    : items[item - 1].text[page].front
                "
              >
                <!-- {{item.isOpen ? items[item - 1].text[page].back : items[item - 1].text[page].front}} -->
              </h3>
              <p
                class="functionality__text"
                v-if="!items[item - 1].isOpen"
                v-html="items[item - 1].text[page].back.text"
              >
                {{ items[item - 1].text[page].back.text }}
              </p>
              <img
                v-if="items[item - 1].icon && items[item - 1].isOpen"
                class="functionality__logo"
                :src="`static/img/cards/card-${items[item - 1].icon[page]}.png`"
                :alt="`logo taxi-aggregator`"
              />
              <button
                class="functionality__btn"
                @click="toggleCard(items[item - 1])"
                :style="`${
                  !items[item - 1].isOpen
                    ? `top: initial; margin-top: initial;`
                    : ``
                }`"
              >
                <i
                  ><svg
                    v-if="items[item - 1].isOpen"
                    width="32"
                    height="32"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <rect x="3" y="11" width="4" height="2" fill="white" />
                    <rect x="10" y="11" width="4" height="2" fill="white" />
                    <rect x="17" y="11" width="4" height="2" fill="white" />
                  </svg>
                  <svg
                    v-else
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="16"
                      cy="16"
                      r="16"
                      transform="rotate(-180 16 16)"
                      fill="#005051"
                    />
                    <g clip-path="url(#clip0_1979_28243)">
                      <path
                        d="M13.0223 14.993H22V16.9883H13.0223V14.993Z"
                        fill="white"
                      />
                      <path
                        d="M11.0257 15.4095V15.9906V16.5718L12.4386 17.986H13.0223V19.9813H11.6131L9.03038 17.3986V15.9906V14.5827L11.6131 12H13.0223V13.9953H12.4386L11.0257 15.4095Z"
                        fill="white"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1979_28243">
                        <rect
                          width="24"
                          height="24"
                          fill="white"
                          transform="matrix(-1 0 0 1 28 4)"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                </i>
                {{ !items[item - 1].isOpen ? "" : "Подробнее" }}
              </button>
            </div>
          </div>
        </div>
      </agile>
    </div>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueAgile } from "vue-agile";

@Component({
  components: {
    agile: VueAgile,
  },
})
export default class Functionality extends Vue {
  @Prop({ type: String, default: "index" }) page;

  /* sliderList = [[1], [4, 5, 6], [2, 8, 9], [3], [7]]; */

  items = [
    {
      id: 1,
      text: {
        index: {
          front: "Выбирайте удобный способ вывода денег",
          back: {
            title: "Выбирайте удобный способ вывода денег",
            text: "Мы работаем со всеми Российскими банками. Вы можете выводить деньги на счет любого из них, а еще у нас доступны выплаты по СБП.",
          },
        },
        driver: {
          front: "Выбирайте удобный способ вывода денег",
          back: {
            title: "Выбирайте удобный способ вывода денег",
            text: "Выводите деньги на счёт любого российского банка – у нас есть из чего выбрать.",
          },
        },
        park: {
          front: "Все финансовые потоки в одном месте",
          back: {
            title: "Все финансовые потоки в одном месте",
            text: "В личном кабинете ежедневно формируются отчёты по совершённым поездкам и выплатам, которые можно скачать в формате Excel. С их помощью удобно следить за операционной деятельностью таксопарка и планировать его развитие.",
          },
        },
        driverNew: {
          front: "Моментальный вывод <br> на карту любого банка",
          back: {
            title: "Выбирайте удобный способ вывода денег",
            text: "Выводите деньги на счёт любого российского банка или заведите под эти цели QIWI кошелек  – у нас есть из чего выбрать.",
          },
        },
      },
      image: {
        index: "bank-light",
        driver: "bank-light",
        park: "bank-park",
        driverNew: "bank-light",
      },
      icon: {
        index: "apply-light",
        driver: "apply-green-driver",
        park: "apply-light-park",
        driverNew: "apply-green-driver",
      },
      isOpen: "false",
    },
    {
      id: 2,
      text: {
        index: {
          front: `15 минут от настройки сервиса до первых выплат`,
          back: {
            title: "15 минут от настройки сервиса до первых выплат ",
            text: "Мы заботимся о том, чтобы наш сервис был интуитивно понятным и удобным, поэтому настройка сервиса и вывод денег занимает считанные минуты.",
          },
        },
        driver: {
          front: "Настраивайте <br> за 15 минут",
          back: {
            title: "15 минут от настройки сервиса до первых выплат",
            text: "Мы заботимся о том, чтобы наш сервис был интуитивно понятным и удобным, поэтому настройка сервиса и вывод денег занимает считанные минуты.",
          },
        },
        park: {
          front: "4.9 Рейтинг <br> приложения",
          back: {
            title: "4.9 Рейтинг <br> приложения",
            text: "Владельцы таксопарков высоко оценивают удобство, быстроту и функционал нашего мобильного приложения.",
          },
        },
        driverNew: {
          front: "Настраивайте <br> за 15 минут",
          back: {
            title: "15 минут от настройки сервиса до первых выплат",
            text: "Мы заботимся о том, чтобы наш сервис был интуитивно понятным и удобным, поэтому настройка сервиса и вывод денег занимает считанные минуты.",
          },
        },
      },
      icon: {
        index: "people",
        driver: "people-driver",
        park: "people-park",
        driverNew: "people-driver",
      },
      isOpen: "false",
    },
    {
      id: 3,
      text: {
        index: {
          front: `Деньги у вас через 26 секунд`,
          back: {
            title: "Деньги у вас через 26 секунд",
            text: "Много ли можно успеть за это время? <br> - перейти светофор <br> - принять заказ <br> - разблокировать телефон <br><br> .. а самое главное -получить свои средства на любую карту",
          },
        },
        driver: {
          front: "Деньги у вас через 26 секунд",
          back: {
            title: "Деньги у вас через 26 секунд",
            text: "За это время можно успеть принять заказ, выехать на его выполнение и переключить песню на магнитоле. А можно просто посидеть в радостном предвкушении выплаты.",
          },
        },
        park: {
          front: "Освободите время для важных дел",
          back: {
            title: "Освободите время для важных дел",
            text: "При создании личного кабинета сервис сам формирует список ваших водителей, а после этого даёт им возможность выводить деньги на счёт. Никакой ручной работы.",
          },
        },
        driverNew: {
          front: "Самые быстрые <br> выплаты <br> за 26 секунд",
          back: {
            title: "Деньги у вас через 26 секунд",
            text: "За это время можно успеть принять заказ, выехать на его выполнение и переключить песню на магнитоле. А можно просто посидеть в радостном предвкушении выплаты.",
          },
        },
      },
      image: {
        index: "fast",
        driver: "fast-driver",
        park: "fast-park",
        driverNew: "fast-driver",
      },
      isOpen: "false",
    },
    {
      id: 4,
      text: {
        index: {
          front: "В поддержке реальные люди",
          back: {
            title: "В поддержке реальные люди",
            text: "В нашей поддержке реальные люди, готовые быстро помочь с любым вопросом. Они работают только для вас каждый день с 9 утра до 21 вечера.",
          },
        },
        driver: {
          front: "В поддержке реальные люди",
          back: {
            title: "В поддержке реальные люди",
            text: "В нашей поддержке работают реальные люди, готовые помочь вам с любыми вопросами – от вывода денег до антифрод-системы. Каждый день с 9 утра до 21 вечера.",
          },
        },
        park: {
          front: "Самая низкая комиссия",
          back: {
            title: "Самая низкая комиссия",
            text: "Банк QIWI – генеральный партнёр Таксиагрегатора, поэтому для наших клиентов он предлагает самую низкую комиссию за вывод денег с расчётного счёта на счёт таксопарка – от 0,1%. Хороший повод сэкономить.",
          },
        },
        driverNew: {
          front: "В поддержке реальные люди",
          back: {
            title: "В поддержке реальные люди",
            text: "В нашей поддержке работают реальные люди, готовые помочь вам с любыми вопросами – от вывода денег до антифрод-системы. Каждый день с 9 утра до 21 вечера.",
          },
        },
      },
      /* image: "help", */
      icon: {
        index: "dialog",
        driver: "dialog",
        park: "statistic-park",
        driverNew: "dialog",
      },
      isOpen: "false",
    },
    {
      id: 5,
      text: {
        index: {
          front: "0 ₽ без доплаты <br> за сервис",
          back: {
            title: "0 ₽ без доплаты <br> за сервис",
            text: "Никаких «скрытых платежей» в виде подписок, абонентских плат и других регулярных оплат. Полностью бесплатный сервис.",
          },
        },
        driver: {
          front: "0 ₽ <br> без доплаты за сервис",
          back: {
            title: "Без абонементов и ежемесячных списаний",
            text: "Никаких «подводных камней» в виде подписок, абонементов и других регулярных выплат. Полностью бесплатный сервис.",
          },
        },
        park: {
          front: "Помогаем расширить ваш таксопарк",
          back: {
            title: "Помогаем расширить ваш таксопарк",
            text: "По нашему опыту, 10 активных водителей приводят в таксопарк 6 новых. Мы создали реферальную программу, в которой водители получают деньги за то, что приводят друзей на работу. Расширяйте таксопарк бесплатно и повышайте лояльность своих сотрудников.",
          },
        },
        driverNew: {
          front: "0 ₽ за вывод <br> с картой Таксиагрегатор",
          back: {
            title: "Без абонементов и ежемесячных списаний",
            text: "Никаких «подводных камней» в виде подписок, абонементов и других регулярных выплат. Полностью бесплатный сервис.",
          },
        },
      },
      icon: {
        index: "hand",
        driver: "hand-driver",
        park: "hand-park",
        driverNew: "hand-driver",
      },
      isOpen: "false",
    },
    {
      id: 6,
      text: {
        index: {
          front: "Все агрегаторы в одном месте",
          back: {
            title: "Все агрегаторы в одном месте",
            text: "С нами Яндекс Про, Uber, Ситимобил, СберМаркет, Таксовичкоф, Dostavista. Как только на рынке появится новый игрок – он также станет нашим партнером.",
          },
        },
        driver: {
          front: "Работаем со всеми агрегаторами<br>на рынке",
          back: {
            title: "Работаем со всеми агрегаторами на рынке",
            text: "С нами Яндекс.Такси, Uber, Ситимобил, СберМаркет, Таксовичкоф, Dostavista и другие российские агрегаторы такси и доставки. Как только на рынке появится новый игрок – он также станет нашим партнёром.",
          },
        },
        park: {
          front: "Все агрегаторы в одном месте",
          back: {
            title: "Все агрегаторы такси в одном месте",
            text: "Работаем со всеми российскими агрегаторами, чтобы у ваших водителей был большой выбор поездок. Как только на рынке появится новый агрегатор – он также станет нашим партнёром.",
          },
        },
        driverNew: {
          front: "Все агрегаторы <br> в одном месте",
          back: {
            title: "Работаем со всеми агрегаторами на рынке",
            text: "С нами Яндекс.Такси, Uber, Ситимобил, СберМаркет, Таксовичкоф, Dostavista и другие российские агрегаторы такси и доставки. Как только на рынке появится новый игрок – он также станет нашим партнёром.",
          },
        },
      },
      icon: {
        index: "services",
        driver: "services",
        park: "services",
        driverNew: "services",
      },
      isOpen: "false",
    },
    {
      id: 7,
      text: {
        index: {
          front: "Реферальная программа, от которой все в плюсе",
          back: {
            title: "Реферальная программа, от которой все в плюсе",
            text: "Наша реферальная программа позволяет водителям получать дополнительный заработок, а владельцам бизнеса – бесплатно расширять свой таксопарк. Как это работает? Водители приводят своих друзей на работу и получают за них денежные бонусы после выполнения условий, поставленных таксопарком для новых сотрудников.",
          },
        },
        driver: {
          front: "Настраивайте <br> за 15 минут",
          back: {
            title: "Реферальная программа, от которой все в плюсе",
            text: "Наша реферальная программа позволяет водителям получать дополнительный заработок, а владельцам бизнеса – бесплатно расширять свой таксопарк. Как это работает? Водители приводят своих друзей на работу и получают за них денежные бонусы после выполнения условий, поставленных таксопарком для новых сотрудников.",
          },
        },
        park: {
          front: "Размер не имеет значения",
          back: {
            title: "Размер не имеет значения",
            text: "Мы одинаково хорошо работаем с таксопарками с 5 и 25 000 водителей.",
          },
        },
        driverNew: {
          front: "Настраивайте <br> за 15 минут",
          back: {
            title: "Реферальная программа, от которой все в плюсе",
            text: "Наша реферальная программа позволяет водителям получать дополнительный заработок, а владельцам бизнеса – бесплатно расширять свой таксопарк. Как это работает? Водители приводят своих друзей на работу и получают за них денежные бонусы после выполнения условий, поставленных таксопарком для новых сотрудников.",
          },
        },
      },
      image: {
        index: "bank",
        driver: "bank",
        park: "bank",
        driverNew: "bank",
      },
      icon: {
        index: "apply-green",
        driver: "apply-green-driver",
        park: "apply-green-park",
        driverNew: "apply-green-driver",
      },
      isOpen: "false",
    },
    {
      id: 8,
      text: {
        index: {
          front: "Ваши деньги в безопасности",
          back: {
            title: "Ваши деньги в безопасности",
            text: "Для таксопарков у нас работает гибкая антифрод-система, а водителям мы помогаем предотвратить кражу денег со счёта. Оперативно реагируем на проблемы.",
          },
        },
        driver: {
          front: "от 0,7% за перевод на карту",
          back: {
            title: "Ваши деньги в безопасности",
            text: "Для таксопарков у нас работает гибкая антифрод-система, а водителям мы помогаем предотвратить кражу денег со счёта. Оперативно реагируем на проблемы.",
          },
        },
        park: {
          front: "0 ₽ за <br> обслуживание",
          back: {
            title: "0 ₽ за <br> обслуживание",
            text: "Абсолютно бесплатный сервис без дополнительных услуг и подписок.",
          },
        },
        driverNew: {
          front: "от 0,7% за перевод на карту",
          back: {
            title: "Ваши деньги в безопасности",
            text: "Для таксопарков у нас работает гибкая антифрод-система, а водителям мы помогаем предотвратить кражу денег со счёта. Оперативно реагируем на проблемы.",
          },
        },
      },
      icon: {
        index: "apply-card",
        driver: "apply-card-driver",
        park: "apply-card-park",
        driverNew: "apply-card-driver",
      },
      isOpen: "false",
    },
    {
      id: 9,
      text: {
        index: {
          front: "Самые низкие комиссии на рынке",
          back: {
            title: "Самые низкие комиссии на рынке",
            text: "Благодаря партнёрству с банком Точка мы предлагаем низкие комиссии на рынке: до 0,8% за вывод денег на банковскую карту и фиксировано 25 рублей на СБП.",
          },
        },
        driver: {
          front: "от 0,1% <br> за перевод <br> на QIWI",
          back: {
            title: "Самые низкие комиссии на рынке",
            text: "Благодаря партнёрству с банком Точка мы предлагаем низкие комиссии на рынке: до 0,8% за вывод денег на банковскую карту и фиксировано 25 рублей на СБП.",
          },
        },
        park: {
          front: "Подпись оферты <br> в один клик",
          back: {
            title: "Подпись оферты <br> в один клик",
            text: "Защитите себя юридически, подписав договор оферты с водителями. Возьмите наш шаблон договора или добавьте свой, а водители подпишут его в приложении.",
          },
        },
        driverNew: {
          front: "от 0,1% <br> за перевод <br> на QIWI",
          back: {
            title: "Самые низкие комиссии на рынке",
            text: "Благодаря партнёрству с банком QIWI мы предлагаем самые низкие комиссии на рынке: от 0,6% за вывод денег на банковскую карту и фиксировано 25 рублей на СБП.",
          },
        },
      },
      icon: {
        index: "statistic",
        driver: "statistic-driver",
        park: "dialog",
        driverNew: "statistic-driver",
      },
      isOpen: "false",
    },
  ];

  toggleCard(card) {
    card.isOpen = !card.isOpen;
  }

  backgroundImg(value) {
    return value ? `url(static/img/cards/card-${value}.png)` : "none";
  }

  get sliderList() {
    if (this.page === "driver" || this.page === "driverNew") {
      return [[1], [4, 5, 6], [3]];
    } else {
      return [[1], [4, 5, 6], [2, 8, 9], [3], [7]];
    }
  }
}
</script>

<style lang="scss">
.flip-enter-active {
  transition: all 0.4s ease;
}

.flip-leave-active {
  display: none;
}

.flip-enter,
.flip-leave {
  transform: rotateY(180deg);
  opacity: 0;
}

.functionality {
  .slider {
    display: none;
  }

  &__text {
    margin-top: auto;
    margin-bottom: 1.5vw;
    font-weight: 500;

    font-size: 25px;
    line-height: 31px;
  }

  &__btn {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84px;
    height: 32px;
    position: relative;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    letter-spacing: 0.5px;

    color: #ffffff;

    border: none;
    background: none;

    cursor: pointer;

    &:hover {
      opacity: 0.5;
      i {
        opacity: 0.5;
      }
    }

    i {
      margin: 0;
      font-size: 32px;
      border-radius: 50%;
      margin-right: 16px;

      &::before {
        margin: 0;
      }
    }
  }

  &__logo {
    width: auto;
    height: fit-content;
    margin-top: auto;
    margin-left: auto;
  }

  &__wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
  }

  &--driver,
  &--driverNew {
    .functionality__list {
      grid-template-areas:
        "i1 i1 i6"
        "i1 i1 i3"
        "i4 i5 i3";
      width: 100%;
      height: 1294px;
    }
  }

  &__list {
    display: grid;
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    gap: 16px 8px;
    grid-auto-flow: row;
    grid-template-areas:
      "i1 i1 i2"
      "i1 i1 i3"
      "i4 i5 i3"
      "i6 i6 i7"
      "i8 i9 i7";
    width: 100%;
    height: 2168px;
  }

  &__item3,
  &__item6 {
    background-color: #fffad7;
    .functionality__logo {
      width: inherit;
    }
  }

  &--index {
    .functionality {
      &__btn {
        i {
          background-color: #38ce97;

          circle {
            fill: #38ce97;
          }
        }
      }

      &__item1,
      &__item9 {
        background-color: #57d7a5;

        .functionality__btn {
          i {
            background: #005051;

            circle {
              fill: #005051;
            }
          }
        }
      }

      &__item2,
      &__item4 {
        background-color: #a562ff;

        .functionality__btn {
          i {
            background: #005051;

            circle {
              fill: #005051;
            }
          }
        }
      }

      &__item3 {
        .functionality__title {
          color: #000;
        }
      }

      &__item5,
      &__item7,
      &__item8 {
        background-color: #005051;
      }

      &__item1 {
        background-position: center 65%;
        background-size: 45%;
      }
    }
  }

  /*  -------- */

  &--driver,
  &--driverNew {
    .functionality {
      &__btn {
        i {
          background-color: #38ce97;

          circle {
            fill: #38ce97;
          }
        }
      }

      &__item1,
      &__item9,
      &__item4 {
        background-color: #005051;
      }

      &__item2,
      &__item5,
      &__item7,
      &__item8 {
        background-color: #38ce97;

        .functionality__btn {
          i {
            background: #005051;

            circle {
              fill: #005051;
            }
          }
        }
      }

      &__item6 {
        background-color: #38ce97;
        color: #fff;

        .functionality__btn {
          color: #fff;

          i {
            background: #005051;

            circle {
              fill: #005051;
            }
          }
        }

        .functionality__title {
          z-index: 2;
        }
      }

      &__item1 {
        background-position: center 65%;
        background-size: 45%;
        .functionality__logo {
          width: 249px;
        }
      }

      &__item2,
      &__item7,
      &__item8,
      &__item9 {
        display: none;
      }
    }
  }

  /* ------ */

  &--park {
    .functionality {
      &__btn {
        i {
          background-color: #a562ff;

          circle {
            fill: #a562ff;
          }
        }
      }

      &__list {
        grid-template-areas:
          "i1 i1 i2"
          "i1 i1 i3"
          "i5 i5 i3"
          "i6 i6 i7"
          "i8 i9 i7";
      }

      &__item1,
      &__item7,
      &__item8 {
        background-color: #40256b;
      }

      &__item5 {
        background-color: #a562ff;
        max-width: 680px;

        .functionality__title {
          font-size: 32px;
          line-height: 36px;
        }

        .functionality__text {
          margin-bottom: 1.5vw;
          font-weight: 500;
          font-size: 23px;
          line-height: 31px;
        }

        .functionality__btn {
          i {
            background: #40256b;

            circle {
              fill: #40256b;
            }
          }
        }
      }

      &__item2,
      &__item9 {
        background-color: #a562ff;

        .functionality__btn {
          i {
            background: #40256b;

            circle {
              fill: #40256b;
            }
          }
        }
      }

      &__item4 {
        display: none;
      }
    }
  }

  &__item {
    background-position: center;
    padding: 33px;
    border-radius: 28px;
    background-repeat: no-repeat;

    &1,
    &3,
    &7 {
      font-family: "GTAmericaEx", "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      text-align: left;
      height: 858px;
      max-width: 680px;
      box-sizing: border-box;

      color: #ffffff;

      .functionality__text {
        margin-bottom: 33px;
        font-size: 25px;
        line-height: 31px;
      }
    }

    &2,
    &4,
    &5,
    &6,
    &8,
    &9 {
      font-family: "GTAmericaEx", "Roboto Medium";
      font-style: normal;
      font-weight: 500;
      font-size: 32px;
      line-height: 36px;
      text-align: left;

      color: #ffffff;

      /* .functionality__title {
        width: 80%;
      } */
    }

    &6 {
      box-sizing: border-box;
      max-height: 420px;
      max-width: 680px;
      overflow: hidden;

      /* .functionality__title {
        max-width: 50%;
      } */

      .functionality__wrapper {
        position: relative;
        justify-content: space-between;
      }

      .functionality__logo {
        position: absolute;
        width: 395px;
        height: auto;
        top: 85px;
        right: 0;
      }
    }

    &4,
    &5,
    &8,
    &9 {
      font-size: 24px;
      line-height: 32px;
      box-sizing: border-box;
      max-width: 332px;

      .functionality__text {
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 17px;
      }
    }

    &3,
    &6 {
      color: #000000;

      .functionality__btn {
        color: #000;
      }
    }

    &3 {
      .functionality__btn {
        margin-top: auto;
      }
    }

    &1,
    &7 {
      /* .functionality__title {
        width: 70%;
      } */

      .functionality__logo {
        margin-right: 32px;
      }
    }
  }

  @media (max-width: 600px) {
    .slider {
      display: block;
    }

    .agile__slides {
      height: 384px;
    }

    .slider-wrapper {
      display: flex;
      flex-wrap: wrap;
    }

    .functionality {
      &__text {
        font-size: 2vw;
        line-height: 2vw;
      }

      &__list {
        display: none;
      }

      /* .agile__slide {
        display: flex;
        flex-wrap: wrap;
      } */

      &__wrapper {
        width: auto;
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      &__item {
        padding: 17px;
        border-radius: 16px;
        box-sizing: border-box;
        .functionality__btn {
          height: 25px;

          font-weight: 500;
          font-size: 12px;
          line-height: 15px;

          i {
            margin-right: 9px;
            svg {
              width: 24px;
              height: 24px;
            }
          }
        }

        &1,
        &3,
        &7 {
          height: 384px;
          min-width: 100%;
          .functionality__title {
            font-family: "GTAmericaEx", "Roboto";
            width: 100%;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;

            /* letter-spacing: 3px; */
          }

          .functionality__text {
            font-size: 12px;
            line-height: 16px;
          }
        }

        &1,
        &7 {
          background-size: contain;
          background-position-y: 9px;
          background-size: 97%;

          .functionality__logo {
            margin-top: 222px;
            margin-right: 0px;
            height: 90px;
          }

          .functionality__btn {
            position: relative;
            top: -20px;
          }
        }

        &4,
        &5,
        &6,
        &8,
        &2,
        &9 {
          font-weight: 500;
          font-size: 15px;
          line-height: 20px;
        }

        &4,
        &5,
        &8,
        &9 {
          max-width: calc(1 / 2 * 100% - (1 - 1 / 2) * 8px);
          min-width: calc(1 / 2 * 100% - (1 - 1 / 2) * 8px);
          min-height: 188px;
          max-height: 188px;

          .functionality__logo {
            height: 55px;
            margin-right: 0px;
            margin-top: 11px;
            margin-bottom: 11px;
          }

          .functionality__title {
            font-size: 11px;
            line-height: 11px;
            width: 100%;
          }

          .functionality__text {
            margin-bottom: 2vw;
          }
        }

        &5,
        &9 {
          margin-left: auto;
        }

        &6,
        &2 {
          margin-top: 8px;
          min-width: 100%;
          min-height: 188px;
          max-height: 188px;

          .functionality__btn {
            margin-bottom: 5px;
          }

          .functionality__title {
            max-width: 58%;
          }

          .functionality__logo {
            width: 195px;
            height: auto;
            top: 40px;
            right: 0px;
          }
        }

        &2 {
          margin-top: 0;
          margin-bottom: 8px;

          .functionality__logo {
            width: 54px;
            margin-right: 14px;
          }
        }

        &8,
        &9 {
          .functionality__btn {
            margin-top: 4px;
          }

          .functionality__logo {
            height: 52px;
            margin-right: 0px;
            margin-top: 13px;
            margin-bottom: 8px;
          }
        }

        &3 {
          background-size: contain;

          .functionality__btn {
            margin-bottom: 6px;
          }
        }

        &7 {
          width: 100%;
        }

        &1 {
          background-size: 40%;
          background-position-y: 57px;
        }
      }
    }

    &--driver,
    &--driverNew {
      .functionality {
        &__item1 {
          background-size: 40%;
          background-position-y: 57px;
          .functionality {
            &__logo {
              width: 95px;
              margin-top: 186px;
              margin-right: 5px;
              height: 92px;
            }

            &__btn {
              top: -7px;
            }
          }
        }
      }
    }

    &--park {
      .functionality {
        &__item1 {
          .functionality {
            &__logo {
              margin-top: 167px;
              margin-right: 13px;
              height: 85px;
            }

            &__btn {
              top: -5px;
            }
          }
        }

        &__item5 {
          min-width: 100%;
          .functionality {
            &__title {
              font-weight: 500;
              font-size: 15px;
              line-height: 20px;
            }

            &__text {
              font-size: 2vw;
              line-height: 2vw;
            }

            &__logo {
              margin-top: auto;
            }

            &__btn {
              top: -5px;
            }
          }
        }

        &__item7 {
          .functionality {
            &__logo {
              margin-top: 142px;
              margin-right: 13px;
              height: 85px;
            }

            &__btn {
              top: -5px;
            }
          }
        }

        &__item1 {
          background-size: contain;
          background-position-y: 9px;
          background-size: 97%;
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    &__text {
      font-size: 1.7vw;
      line-height: 1.9vw;
    }
    &--index {
      .functionality__list {
        height: 149.73vw;
      }
    }

    &--driver,
    &--driverNew {
      .functionality__list {
        height: 90.15vw;
      }
    }
    .functionality {
      &__item {
        padding: 2.5vw;
        border-radius: 2.3vw;
      }

      &__btn {
        height: 2.2vw;
        width: 6.6vw;
        font-size: 1.3vw;
        line-height: 1vw;

        i {
          font-size: 2vw;
          margin-right: 1.3vw;

          svg {
            width: 2.6vw;
            height: auto;
          }
        }
      }

      &__title {
        font-size: 2.5vw;
        line-height: 3vw;
      }

      &__logo {
        width: 9.5vw;
      }

      &__item4,
      &__item5 {
        .functionality__logo {
          width: 10vw;
        }
      }

      &__item1 {
        .functionality__logo {
          width: 16.5vw;
          margin-right: 2.5vw;
        }
      }

      &__item7 {
        .functionality__logo {
          width: 14.5vw;
          margin-right: 2.3vw;
        }
      }

      &__item2 {
        .functionality__logo {
          width: 12.5vw;
        }
      }

      &__item6 {
        .functionality__logo {
          width: 30.9vw;
          height: auto;
          top: 6vw;
          right: -4px;
        }
      }

      &__item1,
      &__item3,
      &__item7 {
        max-width: 47vw;
        height: 59.1vw;
        background-size: contain;
        .functionality__text {
          margin-top: auto;
          margin-bottom: 2vw;
          font-weight: 500;
          font-size: 2vw;
          line-height: 2.2vw;
        }
      }

      &__item4,
      &__item5,
      &__item8,
      &__item9 {
        .functionality__title {
          font-size: 1.9vw;
          line-height: 2.6vw;
        }

        .functionality__text {
          font-size: 1vw;
          line-height: 1vw;
          margin-bottom: 1.5vw;
        }

        max-width: 22.8vw;
        min-height: 29vw;
      }

      &__item2,
      &__item6 {
        min-height: 17vw;
      }

      &__item6 {
        max-width: 46.91vw;
      }

      /* &__item6 {
        .functionality__title {
          max-width: 100%;
        }
      } */

      &__item1 {
        background-position: center 65%;
        background-size: 45%;
      }

      &__item7 {
        background-position: bottom 12px left -10px;
        background-size: 100%;
      }
    }

    &--park {
      .functionality {
        &__list {
          height: 149.73vw;
        }
        &__item1 {
          background-size: 108%;
          background-position-y: -36px;
        }

        &__item6 {
          .functionality__logo {
            width: 30.9vw;
            height: auto;
            top: 5vw;
            right: 0px;
          }
        }

        &__item5 {
          max-width: 46.91vw;

          .functionality__title {
            font-size: 2.5vw;
            line-height: 3vw;
          }

          .functionality__text {
            font-size: 1.5vw;
            line-height: 1.9vw;
          }
        }

        &__item7 {
          background-position: bottom -28px left -26px;
          background-size: 109%;
        }
      }
    }
  }

  @media (min-width: 1280px) and (max-width: 1440px) {
    &--index {
      .functionality__list {
        height: 1915px;
      }
    }

    &--driver,
    &--driverNew {
      .functionality__list {
        height: 1153px;
      }
    }
    .functionality {
      &__btn {
        height: 28px;
      }

      &__title {
        font-size: 32px;
        line-height: 36px;
      }

      &__logo {
        width: 124px;
      }

      &__item4,
      &__item5 {
        .functionality__logo {
          width: 129px;
        }
      }

      &__item1 {
        .functionality__logo {
          width: 216px;
          margin-right: 26px;
        }
      }

      &__item7 {
        .functionality__logo {
          width: 187px;
          margin-right: 26px;
        }
      }

      &__item2 {
        .functionality__logo {
          width: 160px;
        }
      }

      &__item6 {
        .functionality__logo {
          width: 395px;
          height: auto;
          top: 69px;
          right: -20px;
        }
      }

      &__item1,
      &__item3,
      &__item7 {
        max-width: 600px;
        height: 756px;
        background-size: contain;
      }

      &__item4,
      &__item5,
      &__item8,
      &__item9 {
        .functionality__title {
          font-size: 24px;
          line-height: 32px;
        }

        .functionality__text {
          font-size: 12px;
          line-height: 15px;
          margin-bottom: 12px;
        }

        max-width: 293px;
        min-height: 368px;
      }

      &__item5 {
        max-width: 600px;
      }

      &__item2,
      &__item6 {
        min-height: 305px;
      }

      &__item6 {
        max-width: 600px;
      }

      /* &__item1,
      &__item3 {
        .functionality__title {
          max-width: 65%;
        }
      } */

      &__item6 {
        /* .functionality__title {
          max-width: 100%;
        } */

        .functionality__text {
          font-size: 22px;
          line-height: 26px;
        }
      }

      &__item1 {
        background-position: center 65%;
        background-size: 45%;
      }

      &__item7 {
        background-position: bottom 12px left -10px;
        background-size: 100%;
      }
    }

    &--park {
      .functionality {
        &__list {
          height: 1915px;
        }
        &__item1 {
          background-size: 108%;
          background-position-y: -36px;
        }

        &__item6 {
          .functionality__logo {
            width: 395px;
            height: auto;
            top: 67px;
            right: -14px;
          }
        }

        &__item5 {
          .functionality__text {
            font-size: 20px;
            line-height: 26px;
          }

          .functionality__title {
            font-size: 32px;
            line-height: 36px;
          }
        }

        &__item7 {
          background-position: bottom -28px left -26px;
          background-size: 109%;
        }
      }
    }
  }
}
</style>
