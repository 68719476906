<template>
  <div class="why-we">
    <p class="why-we__description why-we__description--mobile">
      <span class="why-we__description-mobile--spacebar">&nbsp;</span>Мы сервис моментальных выплат с эффективными и надежными решениями.
    </p>
    <ul class="why-we__list">
      <li class="why-we__item why-we__description--desktop">
        <p class="why-we__description">
          <span class="why-we__description--spacebar">&nbsp;</span>Мы сервис
          моменталь&shy;ных выплат с эффективными и надежными решениями.
        </p>
      </li>
      <li class="why-we__item" v-for="(card, index) in cards" :key="index">
        <img :src="`static/img/icons/${card.icon}.svg`" class="why-we__icon" />
        <p class="why-we__text" v-html="card.text"></p>
      </li>
      <li class="why-we__item">
        <button class="why-we__btn" @click="isMoreOpen = !isMoreOpen">
          <i :class="isMoreOpen ? 'icon-minus' : 'icon-plus'"></i>ЕЩЕ
        </button>
      </li>
    </ul>
    <div class="slider">
      <agile :slidesToShow="1" :navButtons="false">
        <div
          class="slider-item"
          v-for="(slide, index) in slidesList"
          :key="index"
        >
          <div class="why-we__item" v-for="(card, index) in slide" :key="index">
            <img
              :src="`static/img/icons/${card.icon}.svg`"
              class="why-we__icon"
            />
            <p class="why-we__text" v-html="card.text">
            </p>
          </div>
        </div>
      </agile>
    </div>
    <ul class="application">
      <li
        class="application__item"
        :class="`application__item-${btn.name}`"
        v-for="btn in btns"
        :key="btn.id"
      >
        <router-link
          class="application__router"
          :class="`application__router-${btn.name}`"
          :to="btn.href"
          >{{ btn.title }}</router-link
        >
      </li>
      <p class="application__description">Доступно на iOS, Android, Browser</p>
    </ul>
  </div>
</template>

<script>
import { Component, Prop, Vue } from "vue-property-decorator";
import { VueAgile } from "vue-agile";

@Component({
  components: {
    agile: VueAgile,
  },
})
export default class WhyWe extends Vue {
  isMoreOpen = false;
  btns = [
    {
      id: 1,
      name: "driver",
      title: "Для водителей",
      href: "/driver",
    },
    {
      id: 2,
      name: "park",
      title: "Для таксопарков",
      href: "/park",
    },
  ];
  cards = [
    {
      icon: "like",
      text: "Оценка пользователей 4,8",
    },
    { icon: "download", text: "Число скачиваний <br> 500 000+" },
    { icon: "phone", text: "Удобное мобильное приложение" },
    { icon: "browser", text: "Можно работать через веб-браузер" },
    {
      icon: "manage",
      text: "Все агрегаторы такси с нами ",
    },
    {
      icon: "bank",
      text: "Работаем со всеми российскими банками",
    },
    {
      icon: "percent",
      text: "Низкие комиссии: до 0,8% за вывод на карту",
    },
    { icon: "timer", text: "Деньги у вас через 26 секунд" },
  ];

  slidesList = [
    [
      {
        icon: "like",
        text: "Оценка пользователей 4,8",
      },
      { icon: "download", text: "Число скачиваний <br> 500 000+" },
    ],
    [
      { icon: "phone", text: "Есть моб. приложение" },
      { icon: "browser", text: "Можно работать через веб-браузер" },
    ],
    [
      {
        icon: "manage",
        text: "Все агрегаторы с нами, управляй деньгами приложении.",
      },
      {
        icon: "bank",
        text: "Все банки с нам, выводи деньги на счета любых банков.",
      },
    ],
    [
      {
        icon: "percent",
        text: "Низкие комиссии: до 0,8% за вывод на карту",
      },
      { icon: "timer", text: "Всего 26 секунд и деньги у тебя." },
    ],
  ];
}
</script>

<style lang="scss">
.agile__actions {
  margin-top: 17px;
}
</style>

<style lang="scss" scoped>
.slider-item {
  display: flex;
  justify-content: center;
}

.slider {
  margin-top: 12px;
}

.application {
  position: relative;
  margin-top: 66px;
  min-height: 116px;
  display: flex;

  &__router {
    z-index: 500;
    display: block;
    padding: 23px 0;
    max-width: 262px;
    text-decoration: none;

    font-family: "Roboto";
    font-style: normal;
    font-size: 16px;

    background: #40256b;
    border-radius: 6px;
    color: #fff;

    &-driver {
      background: #005051;
      margin-left: auto;
      margin-right: 8px;

      &:hover {
        background: #c5f9e5;
        color: #000;
      }
    }

    &-park {
      background: #a562ff;
      margin-right: auto;
      margin-left: 8px;

      &:hover {
        background: #e2dffa;
        color: #000;
      }
    }
  }

  &__item {
    width: 50%;
    overflow: hidden;
  }

  &__description {
    position: absolute;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    bottom: 0;
    right: 42%;
    color: rgba(0, 0, 0, 0.33);
    letter-spacing: 0.2px;
  }

  @media (max-width: 900px) {
    flex-wrap: wrap;
    padding-bottom: 18px;
    margin-top: 68px;
    min-height: 119px;

    .application {
      &__description {
        width: 100%;
        right: 0;
        letter-spacing: -0.3px;
      }

      &__item {
        width: 100%;
      }

      &__router {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        padding: 15px 0;
        max-width: 100%;
        margin-left: 0;
        margin-right: 0;
      }
    }
  }

  @media (min-width: 901px) and (max-width: 1440px) {
    .application {
      &__description {
        right: 0;
        left: 0;
        margin: auto;
      }
    }
  }
}
.why-we {
  &__description {
    padding-left: 87px;
    padding-top: 69px;
    padding-right: 88px;
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 60px;
    text-align: left;
    height: 100%;
    align-items: center;

    color: #000000;

    box-sizing: border-box;

    &--mobile {
      display: none;
    }

    &--spacebar {
      padding-left: 168px;
    }
  }

  .slider {
    display: none;
  }

  &__text {
    font-family: "GTAmericaEx", "Roboto Medium";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 31px;

    color: #000000;
  }

  &__btn {
    display: none !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84px;
    height: 32px;
    position: relative;

    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-transform: uppercase;

    color: #000000;

    border: none;
    background: none;

    cursor: pointer;

    i {
      margin: 0;
      font-size: 32px;
      border-radius: 50%;
      background: #38ce97;
      color: #fff;

      &::before {
        margin: 0;
      }
    }
  }
  &__list {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    justify-content: center;
    gap: 16px;
  }

  &__item {
    &:not(:first-child) {
      height: 375px;
      width: 24.09%;
      padding: 46px 33px 47px 30px;
      box-sizing: border-box;
      border: 2px solid #a0a0a0;
      border-radius: 28px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      /* cursor: pointer; */

      &:hover {
        background: #005051;
        border: none;

        .why-we__text {
          color: #fff;
        }
      }

      &:last-child {
        border: none;

        &:hover {
          background: inherit;
        }
      }
    }

    &:nth-child(2) {
      .why-we__text {
        width: 50%;
      }
    }

    &:first-child {
      max-width: 1025px;
    }

    &:last-child {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 332px;
      flex-grow: 1;
    }

    .why-we__icon {
      width: 48px;
      height: 48px;
      /* margin-bottom: 115px; */
    }
  }

  @media (max-width: 600px) {
    .slider {
      display: block;
    }
    .why-we {
      &__description {
        padding: 0;
        margin-top: 33px;
        margin-bottom: 33px;
        padding-left: 38px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;

        &--desktop {
          display: none;
        }

        &--mobile {
          display: block;
        }

        &-mobile--spacebar {
          padding-left: 31px;
        }
      }

      &__list {
        display: none;
      }

      &__text {
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        text-align: left;
        /* padding-right: 10px; */
      }

      &__icon {
        margin-bottom: 32px;
        width: 32px;
        height: 32px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        border: 2px solid #a0a0a0;
        border-radius: 16px;
        box-sizing: border-box;
        height: 175px;
        min-width: calc(1 / 2 * 100% - (1 - 1 / 2) * 8px);
        max-width: 148px;
        padding: 17px 13px 14px 11px;
        justify-content: space-between;

        &:nth-child(2) {
          .why-we__text {
            width: 100%;
          }
        }

        &:not(:first-child) {
          height: 175px;
          max-width: 148px;
          margin-left: 8px;
          padding: 0;
          box-sizing: border-box;
          border: 2px solid #a0a0a0;
          border-radius: 16px;
          text-align: left;
          display: flex;
          padding: 17px 13px 14px 11px;
          justify-content: space-between;
          flex-direction: column;

          cursor: pointer;

          &:hover {
            border: 2px solid #005051;
            background: #005051;
          }

          .why-we__icon {
            margin-right: auto;
          }
        }

        &:hover {
          border: 2px solid #005051;
          background: #005051;

          .why-we__text {
            color: #fff;
          }
        }
      }
    }
  }

  @media (min-width: 601px) and (max-width: 1279px) {
    .why-we {
      &__description {
        font-size: 3.3vw;
        line-height: 3.6vw;
        padding-left: 8vw;
        padding-top: 0.2vw;
        padding-right: 0;

        &--spacebar {
          padding-left: 10.5vw;
        }
      }

      &__list {
        justify-content: space-between;
        gap: 1.3vw;
        width: 95vw;
      }

      &__icon {
        margin-bottom: 0;
        width: 4vw;
        height: 3.6vw;
      }

      &__item {
        &:first-child {
          max-width: 65vw;
        }

        &:not(:first-child) {
          padding: 3.6vw 3vw 3.6vw 2.5vw;
          height: 25.7vw;
          max-width: 22.7vw;
          width: 22.7vw;
        }

        &:last-child {
          min-width: 20vw;
        }
      }

      &__text {
        font-size: 1.7vw;
        line-height: 2.2vw;
      }

      &__btn {
        width: 6.5vw;
        height: 1vw;
        font-size: 1.2vw;
        line-height: 1vw;
        i {
          font-size: 2.6vw;
        }
      }
    }
  }

  /* @media (min-width: 1280px) and (max-width: 1279px) {
    .why-we {
      &__description {
        font-size: 3.3vw;
      }

      &__text {
        font-size: 1.7vw;
      }

      &__item {
        &:first-child {
          max-width: 72%;
        }

        &:not(:first-child) {
          height: 330px;
          max-width: 23%;
        }

        &:last-child {
          min-width: 23%;
        }
      }
    }
  } */

  @media (min-width: 1280px) and (max-width: 1440px) {
    .why-we {
      &__description {
        font-size: 42px;
        line-height: 46px;
        padding-left: 104px;
        padding-top: 2px;
        padding-right: 0;

        &--spacebar {
          padding-left: 135px;
        }
      }

      &__list {
        justify-content: space-between;
      }

      &__icon {
        margin-bottom: 0;
      }

      &__item {
        &:first-child {
          max-width: 855px;
        }

        &:not(:first-child) {
          height: 330px;
          max-width: 290px;
        }

        &:last-child {
          min-width: 290px;
        }
      }

      &__text {
        font-size: 22px;
        line-height: 28px;
      }
    }
  }
}
</style>
